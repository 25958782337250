import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { MutationDetailsAttributesFragmentDoc } from '../../../contact/contact-form/graphql/contact.mutation.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateDayMutationMutationVariables = Types.Exact<{
  createDay?: Types.InputMaybe<Types.DayTypeHyperionMutationInput>;
  createDayProducts?: Types.InputMaybe<Array<Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>> | Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>>;
}>;


export type CreateDayMutationMutation = { __typename?: 'HyperionSchemaMutation', dayTypeHyperionMutation?: { __typename?: 'Day', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type UpdateDayMutationMutationVariables = Types.Exact<{
  updateDay?: Types.InputMaybe<Types.DayTypeHyperionMutationInput>;
  createDayProducts?: Types.InputMaybe<Array<Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>> | Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>>;
}>;


export type UpdateDayMutationMutation = { __typename?: 'HyperionSchemaMutation', dayTypeHyperionMutation?: { __typename?: 'Day', id: string, mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type DeleteDayMutationMutationVariables = Types.Exact<{
  deleteDay?: Types.InputMaybe<Types.DayTypeHyperionMutationInput>;
}>;


export type DeleteDayMutationMutation = { __typename?: 'HyperionSchemaMutation', dayTypeHyperionMutation?: { __typename?: 'Day', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export type DeleteProjectproductMutationMutationVariables = Types.Exact<{
  deleteProduct?: Types.InputMaybe<Types.ProjectproductTypeHyperionMutationInput>;
}>;


export type DeleteProjectproductMutationMutation = { __typename?: 'HyperionSchemaMutation', projectproductTypeHyperionMutation?: { __typename?: 'Projectproduct', mutationDetails?: Array<{ __typename?: 'ModelErrorsTypeHyperionBase', argument?: string | null, errorsMsgs?: Array<string | null> | null, infoMsgs?: Array<string | null> | null, model?: string | null, modelErrorsMsgs?: Array<string | null> | null, modelSuccessMsgs?: Array<string | null> | null, modelWarningMsgs?: Array<string | null> | null, msg?: string | null, mutationSucceeded?: boolean | null, successMsgs?: Array<string | null> | null, warningMsgs?: Array<string | null> | null } | null> | null } | null };

export const CreateDayMutationDocument = gql`
    mutation createDayMutation($createDay: DayTypeHyperionMutationInput, $createDayProducts: [ProjectproductTypeHyperionMutationInput]) {
  dayTypeHyperionMutation(
    createDay: $createDay
    createDayProducts: $createDayProducts
  ) {
    id
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateDayMutationGQL extends Apollo.Mutation<CreateDayMutationMutation, CreateDayMutationMutationVariables> {
    document = CreateDayMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateDayMutationDocument = gql`
    mutation updateDayMutation($updateDay: DayTypeHyperionMutationInput, $createDayProducts: [ProjectproductTypeHyperionMutationInput] = []) {
  dayTypeHyperionMutation(
    updateDay: $updateDay
    createDayProducts: $createDayProducts
  ) {
    id
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateDayMutationGQL extends Apollo.Mutation<UpdateDayMutationMutation, UpdateDayMutationMutationVariables> {
    document = UpdateDayMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteDayMutationDocument = gql`
    mutation deleteDayMutation($deleteDay: DayTypeHyperionMutationInput) {
  dayTypeHyperionMutation(deleteDay: $deleteDay) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteDayMutationGQL extends Apollo.Mutation<DeleteDayMutationMutation, DeleteDayMutationMutationVariables> {
    document = DeleteDayMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DeleteProjectproductMutationDocument = gql`
    mutation deleteProjectproductMutation($deleteProduct: ProjectproductTypeHyperionMutationInput) {
  projectproductTypeHyperionMutation(deleteProjectproduct: $deleteProduct) {
    mutationDetails {
      ...MutationDetailsAttributes
    }
  }
}
    ${MutationDetailsAttributesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteProjectproductMutationGQL extends Apollo.Mutation<DeleteProjectproductMutationMutation, DeleteProjectproductMutationMutationVariables> {
    document = DeleteProjectproductMutationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }