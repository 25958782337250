import { gql } from 'apollo-angular';

// GQL FRAGMENTS

const MAIL_FRAGMENT_BODY = gql`
  fragment MailBody on Mail {
    id
    email
    created
    subject
    message
    deliveryStatus
    opens
    clicks
    acceptances {
      edges {
        node {
          id
          offerId
          created
          offer {
            id
            trueIdAndVersion
          }
        }
      }
    }
    attachmentsAsArray_PreMailTypeHyperion {
      edges {
        node {
          name
          url
        }
      }
    }
  }
`;

/** Export GQL Fragments on Mail */
export const Mail = {
  fragments: {
    body: MAIL_FRAGMENT_BODY,
  },
};

// GQL Queries

const PROJECT_MAILS = {
  query: gql`
    query fetchProjectMailsQuery($id: Int) {
      company {
        id
        projects(id: $id) {
          edges {
            node {
              id
              mails {
                edges {
                  node {
                    ...MailBody
                  }
                }
              }
            }
          }
        }
      }
    }
    ${Mail.fragments.body}
  `,
  testVariables: {
    id: 1906,
  },
};

const OFFERS_AND_ATA_MAILS = {
  query: gql`
    query fetchOfferMailsQuery($id: Int) {
      company {
        id
        offersAndAtas(id: $id) {
          edges {
            node {
              id
              type
              mails {
                edges {
                  node {
                    ...MailBody
                  }
                }
              }
            }
          }
        }
      }
    }
    ${Mail.fragments.body}
  `,
  testVariables: {
    id: 1,
  },
};

const ALL_LAST_MAILS = {
  query: gql`
    query fetchAllLastMailsQuery {
      company {
        id
        mails(last: 50) {
          edges {
            node {
              ...MailBody
            }
          }
        }
      }
    }
    ${Mail.fragments.body}
  `,
};

const INVOICES_AND_SUPPLIER_INVOICE_MAILS = {
  query: gql`
    query fetchInvoiceMailsQuery($id: Int) {
      company {
        id
        invociesAndSupplierInvoices(id: $id) {
          edges {
            node {
              id
              type
              mails {
                edges {
                  node {
                    ...MailBody
                  }
                }
              }
            }
          }
        }
      }
    }
    ${Mail.fragments.body}
  `,
  testVariables: {},
};

/** Export GQL Queries on Mail */

export const queries = {
  projectMails: PROJECT_MAILS,
  offersAndAtaMails: OFFERS_AND_ATA_MAILS,
  allLastMails: ALL_LAST_MAILS,
  invociesAndSupplierInvoiceMails: INVOICES_AND_SUPPLIER_INVOICE_MAILS,
};
