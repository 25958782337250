<div class="page-container">
  <p-card>
    <spinner *ngIf="componentLoading"></spinner>
    <div *ngIf="!componentLoading">
      <div class="p-grid">
        <form
          [formGroup]="componentMainForm"
          (keyup.enter)="updateRequest($event)"
          class="p-grid full-width"
        >
          <div class="p-col-12 p-lg-4" #formBody>
            <div class="p-col-12">
              <h2>Faktura & offert</h2>
            </div>

            <label for="betalningsvillkor" class="form-control-label"
              >Betalningsvillkor</label
            >
            <p-dropdown
              formControlName="betalningsvillkor"
              [options]="betalningsvillkorOptions"
            ></p-dropdown>

            <label for="interest" class="form-control-label"
              >Dröjsmålsränta</label
            >
            <input pInputText formControlName="interest" />

            <label for="plusgiro" class="form-control-label">Bankgiro</label>
            <input pInputText formControlName="plusGiro" />

            <label for="postgiro" class="form-control-label">Plusgiro</label>
            <input pInputText formControlName="postGiro" />

            <label for="iban" class="form-control-label">IBAN</label>
            <input pInputText formControlName="iban" />

            <label for="bicSwift" class="form-control-label">BIC/SWIFT</label>
            <input pInputText formControlName="bicSwift" />

            <label for="companyInsurance" class="form-control-label"
              >Försäkringsbolag</label
            >
            <input pInputText formControlName="companyInsurance" />

            <div class="p-d-flex p-my-3">
              <p-checkbox
                [binary]="true"
                [formControl]="componentMainForm.controls.fTax"
                class="p-mr-1"
                label="Godkänt för F-skatt"
              ></p-checkbox>
            </div>
            <div class="p-d-flex p-my-3">
              <p-checkbox
                [binary]="true"
                [formControl]="
                  componentMainForm.controls.showPdfInvoicePricesWithVatPrivate
                "
                label="Visa inkl.moms-priser på faktura (PDF) för privatpersoner"
                class="p-mr-1"
              ></p-checkbox>
            </div>
          </div>

          <div class="p-col-12 p-lg-4 p-g-nopad with-padding" #formBody1>
            <div class="p-col-12">
              <h2>&nbsp;</h2>
            </div>

            <label for="skra" class="form-control-label">Skrå</label>
            <p-dropdown
              formControlName="skra"
              [options]="skraOptions"
            ></p-dropdown>

            <div class="p-col-12">
              <div
                class="form-group inline"
                [class.has-danger]="
                  componentMainForm.controls.standardInvoiceMailText[
                    'showErrors'
                  ]
                "
              >
                <label class="form-control-label"
                  >Standardtext vid mail av faktura</label
                >
                <p-editor
                  formControlName="standardInvoiceMailText"
                  [style]="{ height: '100px' }"
                >
                  <p-header>
                    <span class="ql-formats">
                      <select class="ql-header" aria-label="Header"></select>
                      <button class="ql-link" aria-label="Insert link"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button
                        class="ql-underline"
                        aria-label="Underline"
                      ></button>
                    </span>
                  </p-header>
                </p-editor>
                <div
                  *ngIf="
                    componentMainForm.controls.standardInvoiceMailText[
                      'showErrors'
                    ]
                  "
                >
                  <p
                    *ngFor="
                      let error of componentMainForm.controls
                        .standardInvoiceMailText.mergedErrors
                    "
                    class="form-control-feedback"
                  >
                    <span><i class="fa fa-times"></i> </span
                    ><span [innerHTML]="error"></span>
                  </p>
                </div>
              </div>
            </div>
            <div class="p-col-12">
              <div
                class="form-group inline"
                [class.has-danger]="
                  componentMainForm.controls.standardOfferMailText['showErrors']
                "
              >
                <label class="form-control-label"
                  >Standardtext vid mail av offert</label
                >
                <p-editor
                  formControlName="standardOfferMailText"
                  [style]="{ height: '100px' }"
                >
                  <p-header>
                    <span class="ql-formats">
                      <select class="ql-header" aria-label="Header"></select>
                      <button class="ql-link" aria-label="Insert link"></button>
                    </span>
                    <span class="ql-formats">
                      <button class="ql-bold" aria-label="Bold"></button>
                      <button
                        class="ql-underline"
                        aria-label="Underline"
                      ></button>
                    </span>
                  </p-header>
                </p-editor>

                <label class="form-control-label"
                  >Klicka för att infoga acceptera-länk</label
                >
                <span
                  ><input
                    type="text"
                    class="form-control"
                    [(ngModel)]="acceptLink"
                    [ngModelOptions]="{ standalone: true }"
                    pInputText
                    (click)="insertAcceptLink()"
                    (keydown)="cancelEdit($event)"
                /></span>
                <div
                  *ngIf="
                    componentMainForm.controls.standardOfferMailText[
                      'showErrors'
                    ]
                  "
                >
                  <p
                    *ngFor="
                      let error of componentMainForm.controls
                        .standardOfferMailText.mergedErrors
                    "
                    class="form-control-feedback"
                  >
                    <span><i class="fa fa-times"></i> </span
                    ><span [innerHTML]="error"></span>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="p-col-12 p-lg-4 p-g-nopad">
            <div class="p-col-12">
              <h2>Kostnader för löner</h2>
            </div>

            <label for="socialInsurance" class="form-control-label">FORA</label>
            <input pInputText formControlName="socialInsurance" />

            <label for="trakHalfDayPrice" class="form-control-label"
              >Traktamente, halvdag</label
            >
            <input pInputText formControlName="trakHalfDayPrice" />

            <label for="trakDayPrice" class="form-control-label"
              >Traktamente, heldag</label
            >
            <input pInputText formControlName="trakDayPrice" />

            <label for="trakNightPrice" class="form-control-label"
              >Traktamente, natt</label
            >
            <input pInputText formControlName="trakNightPrice" />

            <label for="weekendHourProcent" class="form-control-label"
              >Pålägg helg</label
            >
            <input pInputText formControlName="weekendHourProcent" />

            <div *ngIf="hasAccountingPlanFunction">
              <label class="p-mt-4">Konto för traktamente, halvdag</label>
              <p-dropdown
                formControlName="trakHalfDayAccountingPlanAccountId"
                *ngIf="companyAccountingPlanAccounts"
                [options]="accountingPlanAccountsDropdownParams.options"
                optionLabel="label"
                optionValue="value"
                filter="true"
                filterBy="label"
              ></p-dropdown>

              <label class="p-mt-4"
                >Omvänd moms-konto för traktamente, halvdag</label
              >
              <p-dropdown
                formControlName="reverseVatTrakHalfDayAccountingPlanAccountId"
                *ngIf="companyAccountingPlanAccounts"
                [options]="accountingPlanAccountsDropdownParams.options"
                optionLabel="label"
                optionValue="value"
                filter="true"
                filterBy="label"
              ></p-dropdown>

              <label class="p-mt-4">Konto för traktamente, heldag</label>
              <p-dropdown
                formControlName="trakDayAccountingPlanAccountId"
                *ngIf="companyAccountingPlanAccounts"
                [options]="accountingPlanAccountsDropdownParams.options"
                optionLabel="label"
                optionValue="value"
                filter="true"
                filterBy="label"
              ></p-dropdown>

              <label class="p-mt-4"
                >Omvänd moms-konto för traktamente, heldag</label
              >
              <p-dropdown
                formControlName="reverseVatTrakDayAccountingPlanAccountId"
                *ngIf="companyAccountingPlanAccounts"
                [options]="accountingPlanAccountsDropdownParams.options"
                optionLabel="label"
                optionValue="value"
                filter="true"
                filterBy="label"
              ></p-dropdown>

              <label class="p-mt-4">Konto för traktamente, natt</label>
              <p-dropdown
                formControlName="trakNightAccountingPlanAccountId"
                *ngIf="companyAccountingPlanAccounts"
                [options]="accountingPlanAccountsDropdownParams.options"
                optionLabel="label"
                optionValue="value"
                filter="true"
                filterBy="label"
              ></p-dropdown>

              <label class="p-mt-4"
                >Omvänd moms-konto för traktamente, natt</label
              >
              <p-dropdown
                formControlName="reverseVatTrakNightAccountingPlanAccountId"
                *ngIf="companyAccountingPlanAccounts"
                [options]="accountingPlanAccountsDropdownParams.options"
                optionLabel="label"
                optionValue="value"
                filter="true"
                filterBy="label"
              ></p-dropdown>
            </div>
          </div>
        </form>
      </div>

      <div class="p-grid p-g-nopad">
        <div class="p-col-12 p-g-nopad align-right">
          <button
            pButton
            type="button"
            class="p-button-primary-hyperion"
            [disabled]="buttons.update.disabled"
            (click)="actionUpdate()"
            label="Uppdatera"
            icon="pi pi-save"
          ></button>
        </div>
      </div>
    </div>
  </p-card>

  <p-card class="p-col-12">
    <app-invoice-mall-settings></app-invoice-mall-settings>
  </p-card>

  <p-card>
    <app-invoice-number-settings></app-invoice-number-settings>
  </p-card>
</div>
