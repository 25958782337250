<div>
  <hyperion-card [class.no-style]="inModal" class="p-grid">
    <div class="p-col-12">
      <h2>Allmänna uppgifter</h2>
    </div>
    <div class="p-col-12 p-g-nopad" *ngIf="loading">
      <spinner></spinner>
    </div>
    <form
      [formGroup]="componentMainForm"
      (keyup.enter)="actionUpdate()"
      class="full-width p-grid p-col-12"
      *ngIf="!loading"
    >
      <div class="p-col-12 p-lg-4 p-g-nopad">
        <appForm2-simple-input
          formControlName="firstName"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="lastName"
        ></appForm2-simple-input>

        <appForm2-simple-input formControlName="email"></appForm2-simple-input>

        <appForm2-simple-dropDown
          formControlName="type"
          [dropDownParams]="{ options: userTypes }"
          [tooltip]="{ msg: tooltip, tooltipPosition: 'left' }"
        ></appForm2-simple-dropDown>

        <appForm2-simple-dropDown
          formControlName="costTypeId"
          [dropDownParams]="{ options: userCostTypes }"
        ></appForm2-simple-dropDown>

        <appForm2-simple-dropDown
          *ngIf="showDivisions"
          formControlName="companyDivisionId"
          [dropDownParams]="{ options: divisionsOptions }"
        ></appForm2-simple-dropDown>
      </div>

      <div class="p-col-12 p-lg-4 p-g-nopad">
        <appForm2-simple-input
          formControlName="mobilePhone"
        ></appForm2-simple-input>

        <appForm2-simple-input formControlName="phone"></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="personalNumber"
        ></appForm2-simple-input>

        <appForm2-simple-textarea
          [rows]="4"
          formControlName="note"
        ></appForm2-simple-textarea>
      </div>

      <div class="p-col-12 p-lg-4 p-g-nopad">
        <appForm2-simple-input
          formControlName="address"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="address2"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="cityCode"
        ></appForm2-simple-input>

        <appForm2-simple-input formControlName="city"></appForm2-simple-input>

        <appForm2-simple-checkbox
          formControlName="allowed"
        ></appForm2-simple-checkbox>
      </div>

      <div class="p-col-12 settings-sub-header">
        <h3>Kontaktpersoner / anhöriga</h3>
      </div>

      <div class="p-col-12 p-lg-4 p-g-nopad">
        <div class="p-col-12">
          <h3>Anhörig 1</h3>
        </div>
        <appForm2-simple-input
          formControlName="relative1name"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="relative1phone"
        ></appForm2-simple-input>

        <appForm2-simple-textarea
          formControlName="relative1other"
        ></appForm2-simple-textarea>
      </div>

      <div class="p-col-12 p-lg-4 p-g-nopad">
        <div class="p-col-12">
          <h3>Anhörig 2</h3>
        </div>
        <appForm2-simple-input
          formControlName="relative2name"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="relative2phone"
        ></appForm2-simple-input>

        <appForm2-simple-textarea
          formControlName="relative2other"
        ></appForm2-simple-textarea>
      </div>

      <div class="p-col-12 settings-sub-header">
        <h3 *ngIf="!isContractor">Löneadministration</h3>
        <h3 *ngIf="isContractor">Ersättning</h3>
      </div>
      <div class="p-col-12 p-lg-4 p-g-nopad">
        <appForm2-simple-input formControlName="salary"></appForm2-simple-input>

        <div class="p-col-12" *ngIf="isContractor">Inhyrd</div>

        <appForm2-simple-input
          *ngIf="!isContractor"
          formControlName="fixedSalary"
        ></appForm2-simple-input>
      </div>
      <div class="p-col-12 p-lg-4 p-g-nopad" *ngIf="!isContractor">
        <appForm2-simple-dropDown
          formControlName="socialFee"
          [dropDownParams]="{ options: socialFees }"
        ></appForm2-simple-dropDown>

        <appForm2-simple-dropDown
          formControlName="skattetabell"
          [dropDownParams]="{ options: skattetabellOptions }"
        ></appForm2-simple-dropDown>

        <div *ngIf="useFortnox">
          <label class="p-d-block p-mt-3">Extern Löneart</label>
          <p-dropdown
            [options]="salaryTypes"
            formControlName="salaryType"
          ></p-dropdown>
        </div>
      </div>
      <div class="p-col-12 p-lg-4" *ngIf="!isContractor">
        <appForm2-simple-input
          formControlName="clearingNr"
        ></appForm2-simple-input>

        <appForm2-simple-input
          formControlName="contoNr"
        ></appForm2-simple-input>

        <appForm2-simple-input
          *ngIf="useFortnox"
          formControlName="fortnoxTrueId"
        ></appForm2-simple-input>
      </div>

      <div class="p-col-12 p-d-flex p-jc-between">
        <button
          pButton
          [disabled]="buttons.updateAndSendUserPassword.disabled"
          (click)="actionUpdateAndSendUserPassword()"
          label="Skicka inloggningsuppgifter"
          icon="pi pi-envelope"
          type="button"
        ></button>
        <div>
          <button
            pButton
            class="p-button-icon-danger p-mr-2"
            [disabled]="buttons.delete.disabled"
            (click)="actionDelete()"
            icon="pi pi-trash"
            type="button"
          ></button>
          <button
            pButton
            class="p-button-primary-hyperion"
            [disabled]="buttons.update.disabled"
            (click)="actionUpdate()"
            label="Spara"
            icon="pi pi-save"
            type="button"
          ></button>
        </div>
      </div>
    </form>
  </hyperion-card>
</div>
