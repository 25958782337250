<div class="page-container p-grid">
  <div class="p-col-12 p-inputgroup">
    <span
      [style.background-color]="
        shortMessage.value
          ? 'var(--btn-danger-color)'
          : 'var(--primary-color-400)'
      "
      [style.color]="'#ffffff'"
      class="p-inputgroup-addon"
    >
      <i class="pi pi-flag-fill"></i>
    </span>
    <input
      pInputText
      #shortMessage
      [style.background-color]="'#ffffff'"
      placeholder="Viktigt Meddelande"
      [value]="(projectData | async)?.shortMessage ?? ''"
      (keyup)="handleKeyUp($event, shortMessage.value)"
    />
    <button
      pButton
      class="p-inputgroup-addon"
      icon="pi pi-save"
      [style.background-color]="'#ffffff'"
      [style.color]="'#000000'"
      (click)="saveMessage(shortMessage.value)"
    ></button>
  </div>
  <div
    class="p-col-12 p-d-flex p-jc-between p-ai-center"
    *ngIf="userFlags.isAdmin || userFlags.isForeman"
  >
    <span>
      <button
        pButton
        class="p-mr-2"
        icon="pi pi-file"
        iconPos="right"
        label="Rapportgenerator"
        (click)="openReport()"
      ></button>

      <button
        pButton
        class="p-mr-2"
        icon="pi pi-wallet"
        iconPos="right"
        label="Fakturera"
        *ngIf="allowCreateInvoice"
        (click)="openInvoiceDialog()"
      ></button>

      <button
        pButton
        class="p-mr-2"
        icon="pi pi-file-excel"
        iconPos="right"
        label="Exportera excel"
        (click)="exportExcel()"
      ></button>

      <button
        pButton
        class="p-mr-2"
        [icon]="allowReporting ? 'pi pi-check' : 'pi pi-times'"
        iconPos="right"
        label="Tidrapportering"
        (click)="toggleAllowReporting()"
      ></button>
    </span>
    <div class="p-d-flex">
      <button
        pButton
        class="p-mr-2"
        [icon]="done ? 'pi pi-check' : 'pi pi-times'"
        iconPos="right"
        [label]="doneLabel"
        (click)="toggleMarkAsDone()"
      ></button>
      <span>
        <button
          pButton
          class="p-mr-2 wide"
          [label]="status.label"
          icon="pi pi-chevron-down"
          iconPos="right"
          (click)="menu.toggle($event)"
          [disabled]="status.value === PROJECT_STATUS.INHOUSE"
          style="width: 115px"
        >
          <p-menu #menu [model]="moveMenu" [popup]="true" appendTo="body">
          </p-menu>
        </button>
        <button
          pButton
          class="p-button-icon-danger"
          icon="pi pi-trash"
          (click)="deleteProject()"
          [disabled]="
            !userFlags.isAdmin && status.value === PROJECT_STATUS.INHOUSE
          "
        ></button>
      </span>
    </div>
  </div>
  <p-accordion
    class="p-col-12"
    [multiple]="true"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
  >
    <p-accordionTab
      #overviewAccordion
      appSaveExpansion="projectOverview"
      header="Översikt"
      [allowOpening]="!isLarge"
    >
      <app-project-overview
        [projectId]="projectId"
        [projectData]="projectData"
        [shouldUpdate]="overviewAccordion.selected"
      ></app-project-overview>
    </p-accordionTab>
  </p-accordion>

  <p-accordion
    class="p-col-12"
    [multiple]="true"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
    *ngIf="(projectData | async).subProjectCount > 0"
  >
    <p-accordionTab appSaveExpansion="projectSubprojects" header="Underprojekt">
      <app-project-subproject-list
        [projectData]="projectData | async"
      ></app-project-subproject-list>
    </p-accordionTab>
  </p-accordion>

  <p-accordion
    class="p-col-12"
    [multiple]="true"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
    *ngIf="(projectData | async).parentId"
  >
    <p-accordionTab appSaveExpansion="projectMainProject" header="Huvudprojekt">
      <app-project-subproject-list
        [projectData]="projectData | async"
      ></app-project-subproject-list>
    </p-accordionTab>
  </p-accordion>

  <p-accordion
    class="p-col-12"
    [multiple]="true"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
  >
    <p-accordionTab
      appSaveExpansion="projectInformation"
      header="Projektinformation"
    >
      <app-project-info-view
        [projectData]="projectData"
        (dataChangedEvent)="onInfoDataChanged($event)"
        (clientChangedEvent)="onClientChanged($event)"
      >
      </app-project-info-view>
    </p-accordionTab>
  </p-accordion>

  <p-accordion
    class="p-col-12"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
  >
    <p-accordionTab
      appSaveExpansion="projectWorkMaterial"
      header="Tidrapporter och kostnader"
      [selected]="true"
    >
      <app-project-work-material
        (dayUpdatedEvent)="refresh()"
        [projectData]="projectData"
        (projectProductUpdatedEvent)="onProjectProductUpdatedEvent($event)"
        [shouldLazyLoad]="isLarge"
      ></app-project-work-material>
    </p-accordionTab>
  </p-accordion>
  <p-accordion
    class="p-col-12"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
  >
    <p-accordionTab
      [selected]="hasExtra"
      appSaveExpansion="projectExtra"
      header="Extra tidrapporter och kostnader"
    >
      <app-project-work-material
        (dayUpdatedEvent)="refresh()"
        [projectData]="projectData"
        (projectProductUpdatedEvent)="onProjectProductUpdatedEvent($event)"
        [isExtra]="true"
        [shouldLazyLoad]="isLarge"
      ></app-project-work-material>
    </p-accordionTab>
  </p-accordion>

  <p-accordion
    class="p-col-12"
    expandIcon="pi pi-fw pi-plus"
    collapseIcon="pi pi-fw pi-minus"
    *ngIf="showEconomy"
  >
    <p-accordionTab
      [selected]="true"
      appSaveExpansion="projectEconomy"
      header="Ekonomi"
    >
      <app-project-economy
        [projectData]="projectData"
        (changed)="refresh()"
      ></app-project-economy>
    </p-accordionTab>
  </p-accordion>
</div>

<p-confirmDialog
  key="confirmMarkAsDone"
  header="Markera som klart?"
  message="Är du säker på att du vill markera projektet som klart?<br><br>Detta kommer också klarmarkera alla arbetsmoment i projektet."
  acceptLabel="Markera som klart"
  rejectLabel="Avbryt"
>
</p-confirmDialog>

<p-confirmDialog
  key="confirmMarkAsNotDone"
  header="Markera som inte klart"
  message="Är du säker på att du vill markera projektet som inte klart?"
  acceptLabel="Markera som inte klart"
  rejectLabel="Avbryt"
>
</p-confirmDialog>

<p-confirmDialog
  key="confirmMarkAsDoneWithChanges"
  header="Markera som klart eller inte klart?"
  message="Vill du markera projektet som klart eller inte klart?<br><br>Markerar du som klart kommer detta också klarmarkera alla arbetsmoment i projektet."
  #confirmMarkAsDoneOrNotDoneDialog
>
  <ng-template pTemplate="footer">
    <button
      pButton
      type="button"
      label="Avbryt"
      (click)="confirmMarkAsDoneOrNotDoneDialog.reject()"
    ></button>
    <button
      pButton
      type="button"
      label="Markera som inte klar"
      (click)="
        updateDoneState(
          PROJECT_DONE_STATE.NOT_DONE,
          confirmMarkAsDoneOrNotDoneDialog
        )
      "
    ></button>
    <button
      pButton
      type="button"
      label="Markera som klar"
      (click)="
        updateDoneState(
          PROJECT_DONE_STATE.DONE,
          confirmMarkAsDoneOrNotDoneDialog
        )
      "
    ></button>
  </ng-template>
</p-confirmDialog>
