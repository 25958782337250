import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProjectChangedService {
  public projectChanged(): void {
    this.projectHasChanged.next(true);
  }

  public projectHasChanged: Subject<boolean> = new Subject<boolean>();
}
