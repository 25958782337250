import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, BehaviorSubject, Subject, combineLatest } from 'rxjs';
import { first, takeUntil, map } from 'rxjs/operators';
import moment from 'moment';

import { DayReportComponent } from '../report';
import { DaysSettingsComponent } from '../settings';
import { HelperService } from '../../shared/helpers/index';
import {
  CompanyUsersService,
  UserLocalStorageService,
} from 'app/shared/user/index';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { ApolloQueryService } from '../../shared/apollo/index';
import {
  CompanyFunctionsService,
  CompanyAllProjectsService,
} from '../../shared/company/index';
import { TableMetadata } from 'app/shared/table/table-metadata';
import PROJECT_STATUS from 'app/shared/global/project-status.enum';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { ConfirmationService, TreeNode } from 'primeng/api';
import { DeleteDayGQL } from 'app/project/project-detail/project-info/project-work-material/project-work-performed/graphql/day.generated';
import { MessageService } from 'app/shared/message';
import { HttpService } from '../../shared/http/index';
import {
  DayForListFragment,
  DaysListGQL,
  DaysListWithDeletedGQL,
} from './graphql/timereports.generated';
import { DeletedUsersGQL } from './graphql/users.generated';
import { Column } from 'app/project/project-list/project-list.component';
import {
  FetchAttendanceTypesGQL,
  FetchCompanyUsersCostTypesGQL,
} from '../day-form/graphql/day.query.generated';

@Component({
  selector: 'day-settings',
  templateUrl: 'handle-days-settings.component.html',
  styleUrls: ['handle-days-settings.component.scss'],
  providers: [AppDialogService, ApolloQueryService],
})
export class DaySettingsComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();

  public sectionLoading = false;

  public projectProductsDataSetCount = 0;
  public projectCommentsDataSetCount = 0;

  public PROJECT_TYPE = {
    PRODUCTS: 'projectProducts',
    COMMENTS: 'projectComments',
  };

  public showSection = null;
  public isDayFormVisible = false;
  public dayToEdit: DayForListFragment;

  public get showSidebar(): boolean {
    return Boolean(this.showSection);
  }

  public set showSidebar(value: boolean) {
    if (value) {
      return;
    }
    this.showSection = null;
  }

  public usersDropdown = [];
  public deletedUsersDropdown = [];
  public showSettings = false;
  public queryName = 'timeReport';

  public functionsThisModel = {};

  public userCostTypes: { [key: string]: string } = {};
  public attendanceTypes: { [key: string]: string } = {};

  public dateRange: {
    from: string;
    to: string;
  } = {
    from: null,
    to: null,
  };

  public projectProductsDataSet = [];
  public projectCommentsDataSet = [];
  public users = [];
  public deletedUsers = [];
  public projects = [];
  public projectDropdown = [];

  checkForCompanyFunctions = [
    'useProjectTypes',
    'usePickUserCostTypeOnTimereport',
    'useOnlyTimestampTimeReport',
    'usePickOvertimeOnTimereport',
    'setMile',
    'setPrivMile',
    'useNotarized',
    'advancedUserCanNotarizeTimereports',
  ];

  public meUser: MeUserWithCompany;

  private usersSub: Subscription;
  private projectSub: Subscription;
  private projectCommentsSub: Subscription;
  private multiProjectProductsSub: Subscription;

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public isReportDisabled = false;

  public rowGroupModel:
    | 'userId'
    | 'projectId'
    | 'attendanceTypeId'
    | 'costTypeId' = 'userId';
  public groupingDropdown = [
    { label: 'Användare', value: 'userId' },
    { label: 'Projekt', value: 'projectId' },
    { label: 'Närvarotyp', value: 'attendanceTypeId' },
    { label: 'Yrkestyp', value: 'costTypeId' },
  ];

  public projectCommentsTableMetadata: TableMetadata = {
    columnsMetadata: [
      { key: 'created', label: 'Skapad' },
      { key: 'comment', label: 'Kommentar' },
      { key: 'userName', label: 'Användare' },
    ],
    emptyStateSettings: {
      model: 'Comments',
      whereToCreate: 'left',
    },
  };

  public projectProductsTableMetadata: TableMetadata = {
    columnsMetadata: [
      { key: 'date', label: 'Skapad' },
      { key: 'benamning', label: 'Benämning' },
      { key: 'userName', label: 'Användare' },
    ],
    emptyStateSettings: {
      model: 'Product',
    },
  };

  public days: DayForListFragment[] = [];
  public daysNodes = new BehaviorSubject<TreeNode<DayForListFragment>[]>([]);
  public daysTotal = new BehaviorSubject<DayForListFragment>({ id: '' });

  public leaveDays: DayForListFragment[] = [];
  public leaveDaysNodes = new BehaviorSubject<TreeNode<DayForListFragment>[]>(
    []
  );
  public leaveDaysTotal = new BehaviorSubject<DayForListFragment>({ id: '' });

  public set splitLeaveDays(value: boolean) {
    this._splitLeaveDays = value;
    this.loadTimeReports();
  }
  public get splitLeaveDays(): boolean {
    return this._splitLeaveDays;
  }
  private _splitLeaveDays = true;

  public columns: Column[];
  public allExpanded = true;

  constructor(
    private allProjectsService: CompanyAllProjectsService,
    private apolloQueryService: ApolloQueryService,
    private companyFunctionsService: CompanyFunctionsService,
    private dialogService: AppDialogService,
    private userService: CompanyUsersService,
    public helperService: HelperService,
    private userLocalStorageService: UserLocalStorageService,
    private confirmationService: ConfirmationService,
    private deleteTimeReportService: DeleteDayGQL,
    private messageService: MessageService,
    private httpService: HttpService,
    private deletedUsersService: DeletedUsersGQL,
    private daysListGQL: DaysListGQL,
    private daysListDeletedGQL: DaysListWithDeletedGQL,
    private fetchAttendanceTypesGQL: FetchAttendanceTypesGQL,
    private fetchCompanyUserCostTypesGQL: FetchCompanyUsersCostTypesGQL
  ) {
    this.dateRange = {
      from: moment().startOf('month').format(this.helperService.dateFormat()),
      to: moment().endOf('month').format(this.helperService.dateFormat()),
    };
  }

  public ngOnInit() {
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
    this.users = [+this.meUser.id];

    this.loadAllProjects([
      PROJECT_STATUS.ONGOING,
      PROJECT_STATUS.FINISHED,
      PROJECT_STATUS.LEAVE,
      PROJECT_STATUS.INHOUSE,
    ]);

    this.loadUsers();
    this.loadDeletedUsers();
    this.loadTimeReports();

    this.companyFunctionsService
      .getCompanyFunctions()
      .pipe(takeUntil(this.destroy$))
      .subscribe(companyFunctions => {
        if (Object.keys(companyFunctions).length > 0) {
          this.getCompanyFunctionsThisModel();
          this.getFunctionsData();
        }
      });
  }
  public getColumns(): Column[] {
    return [
      {
        header: 'Datum',
        field: 'date',
        width: '8rem',
      },
      {
        header: 'Projekt',
        field: 'project',
        width: '15rem',
      },
      {
        header: 'Användare',
        field: 'user',
        width: '15rem',
      },
      {
        header: 'Utfört arbete',
        field: 'doneWork',
        width: '16rem',
      },
      {
        header: 'Yrkestyp',
        field: 'costType',
        width: '6rem',
      },
      {
        header: 'Närvarotyp',
        field: 'attendanceTypeId',
        width: '6rem',
      },
      {
        header: 'Timmar',
        field: 'hours',
        width: '5rem',
      },
      {
        header: 'F. timmar',
        field: 'hoursToInvoice',
        width: '6rem',
      },
      {
        header: 'Mil',
        field: 'mile',
        width: '5rem',
      },
      {
        header: 'Priv. mil',
        field: 'privMile',
        width: '5.5rem',
      },
      {
        header: 'Extra',
        field: 'extra',
        width: '3.5rem',
      },
    ].filter(c => {
      const showProject =
        this.rowGroupModel === 'projectId' ? c.field !== 'project' : true;

      const showUser =
        this.rowGroupModel === 'userId' ? c.field !== 'user' : true;

      const showOvertime = !this.functionsThisModel[
        'usePickOvertimeOnTimereport'
      ]
        ? c.field !== 'attendanceTypeId'
        : true;
      const showUserCostType = !this.functionsThisModel[
        'usePickUserCostTypeOnTimereport'
      ]
        ? c.field !== 'costType'
        : true;

      return showProject && showOvertime && showUserCostType && showUser;
    });
  }

  public loadTimeReports(): void {
    this.isReportDisabled = false;
    const daysQuery = this.daysListGQL
      .fetch({
        projectIds: this.projects,
        userIds: this.users.length > 0 ? this.users : [-1],
        fromDate: this.dateRange.from,
        toDate: this.dateRange.to,
      })
      .pipe(
        first(),
        map(data => data.data.company.daysAll.edges),
        map(edges => edges.map(e => e.node))
      );

    const deletedDaysQuery = this.daysListDeletedGQL
      .fetch({
        projectIds: this.projects,
        userIds: this.deletedUsers,
        fromDate: this.dateRange.from,
        toDate: this.dateRange.to,
      })
      .pipe(
        first(),
        map(data => data.data.company.daysAllForDeleted.edges),
        map(edges => edges.map(e => e.node))
      );

    const queries =
      this.deletedUsers.length === 0
        ? [daysQuery]
        : [daysQuery, deletedDaysQuery];

    combineLatest(queries)
      .pipe(
        first(),
        map(data => data.flat())
      )
      .subscribe(res => {
        const days = this.splitLeaveDays
          ? res.filter(d => d.project.status !== PROJECT_STATUS.LEAVE)
          : res;

        this.days = days;

        this.daysTotal.next(this.getSumDay(days));

        const nodes = this.generateNodes(
          this.groupBy(days, this.rowGroupModel),
          this.getLabelGenerator(this.rowGroupModel)
        );

        this.daysNodes.next(nodes);

        const leaveDays = this.splitLeaveDays
          ? res.filter(d => d.project.status === PROJECT_STATUS.LEAVE)
          : [];
        this.leaveDays = leaveDays;

        this.leaveDaysTotal.next(this.getSumDay(leaveDays));

        const leaveNodes = this.generateNodes(
          this.groupBy(leaveDays, this.rowGroupModel),
          this.rowGroupModel === 'userId'
            ? day => `${day.user.firstName} ${day.user.lastName}`
            : day => `${day.project.trueId}, ${day.project.mark}`
        );

        this.leaveDaysNodes.next(leaveNodes);

        this.isLoading.next(false);
        if (
          this.rowGroupModel === 'attendanceTypeId' ||
          this.rowGroupModel === 'costTypeId'
        ) {
          this.isReportDisabled = true;
        }
        this.columns = this.getColumns();
      });
  }

  private getLabelGenerator(
    field: string
  ): (day: DayForListFragment) => string {
    switch (field) {
      case 'userId':
        return day => `${day.user.firstName} ${day.user.lastName}`;
      case 'projectId':
        return day => `${day.project.trueId}, ${day.project.mark}`;
      case 'attendanceTypeId':
        return day => day.attendanceType?.name;
      case 'costTypeId':
        return day =>
          this.userCostTypes[day.costTypeHyperion.companyCostTypeId];
    }
  }

  private generateNodes(
    map: { [key: string]: DayForListFragment[] },
    labelGenerator: (day: DayForListFragment) => string
  ): TreeNode<DayForListFragment>[] {
    return Object.keys(map).map(key => {
      const days = map[key];
      const label = labelGenerator(days[0]);
      const totals = { ...this.getSumDay(days), date: label };
      const children = days.map(day => ({ data: day }));

      return { data: totals, children, expanded: true };
    });
  }

  private groupBy(
    days: DayForListFragment[],
    field: string
  ): { [key: string]: DayForListFragment[] } {
    const map: { [key: string]: DayForListFragment[] } = {};
    days.forEach(day => {
      const value =
        field === 'costTypeId'
          ? day.costTypeHyperion.companyCostTypeId
          : day[field];
      if (!map[value]) {
        map[value] = [];
      }
      map[value].push(day);
    });

    return map;
  }

  private getSumDay(days: DayForListFragment[]): DayForListFragment {
    const sum: DayForListFragment = {
      id: 'sum',
      hours: days.reduce((acc, day) => acc + day.hours, 0),
      hoursToInvoice: days.reduce((acc, day) => acc + day.hoursToInvoice, 0),
      mile: days.reduce((acc, day) => acc + day.mile, 0),
      privMile: days.reduce((acc, day) => acc + day.privMile, 0),
    };

    return sum;
  }

  public openDayHandleDialog(): void {
    this.isDayFormVisible = true;
  }

  public openSettingsDialog(): void {
    this.dialogService.layout = 'auto';
    this.dialogService.openComponent(DaysSettingsComponent);
  }

  /** Opens the PDF export */
  public openDayReportDialog(): void {
    if (this.isReportDisabled) {
      return;
    }
    this.dialogService.data = {
      users: this.users,
      rowGrouping: this.rowGroupModel,
      timeSpan: this.dateRange,
      functionsThisModel: this.functionsThisModel,
      project: null,
      dataSet: {
        days: this.days
          .sort((a, b) => a.date.localeCompare(b.date))
          .sort((a, b) =>
            a[this.rowGroupModel] > b[this.rowGroupModel] ? 1 : -1
          )
          .reverse(),
        daysLeave: this.leaveDays
          .sort((a, b) => a.date.localeCompare(b.date))
          .sort((a, b) =>
            a[this.rowGroupModel] > b[this.rowGroupModel] ? 1 : -1
          )
          .reverse(),
      },
    };
    this.dialogService.layout = 'wide';
    this.dialogService.openComponent(DayReportComponent);
  }

  public getStatisticsReport(): void {
    const exportFileVariables = {
      users: [...this.users, ...this.deletedUsers],
      fromDate: this.dateRange.from,
      toDate: this.dateRange.to,
    };
    this.httpService
      .makeHttpPostRequest('/day/statistics', exportFileVariables)
      .subscribe(data => {
        const fileDownloadLink = data['url'];
        location.href = fileDownloadLink;
      });
  }

  public hideDayFormAndReloadTimeReports(): void {
    this.isDayFormVisible = false;
    this.dayToEdit = null;
    this.loadTimeReports();
  }

  public actionEdit(day: DayForListFragment): void {
    this.dayToEdit = day;
    this.isDayFormVisible = true;
  }

  public actionDelete(day: DayForListFragment): void {
    const dayId = Number(day.id);
    let message = 'Är du säker på att du vill ta bort tidrapporten?';
    if (day.stats?.productCount > 0 || day.stats?.childCount > 0) {
      message +=
        ' Samtliga kopplade produkter och tidrapporter kommer raderas.';
    }
    this.confirmationService.confirm({
      message: message,
      header: 'Bekräfta val',
      icon: 'fa fa-trash',
      accept: () => {
        this.deleteTimeReport(dayId);
      },
    });
  }

  public actionToggleExpandAll(): void {
    this.allExpanded = !this.allExpanded;
    this.daysNodes.next(
      this.daysNodes.value.map(node => ({
        ...node,
        expanded: this.allExpanded,
      }))
    );
    this.leaveDaysNodes.next(
      this.leaveDaysNodes.value.map(node => ({
        ...node,
        expanded: this.allExpanded,
      }))
    );
  }

  private deleteTimeReport(dayId: number): void {
    this.deleteTimeReportService
      .mutate({
        deleteDay: { id: dayId },
      })
      .pipe(first())
      .subscribe(result => {
        const mutationData = result?.data?.dayTypeHyperionMutation;
        if (!mutationData) {
          return;
        }
        this.messageService.insertDataFromMutation(mutationData);
        this.loadTimeReports();
      });
  }

  public toggleSection(mode: string): void {
    this.showSection = mode ? mode : null;

    if (mode === this.PROJECT_TYPE.PRODUCTS) {
      this.getMultiProjectProducts();
    } else if (mode === this.PROJECT_TYPE.COMMENTS) {
      this.getMultiProjectComments();
    }
  }

  private loadUsers(): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.usersDropdown = this.userService.makeLabelsArray(data);
      });
  }

  private loadDeletedUsers(): void {
    this.deletedUsersService
      .fetch()
      .pipe(
        first(),
        map(r => r.data.company.deletedUsers.edges.map(e => e.node))
      )
      .subscribe(users => {
        this.deletedUsersDropdown = users.map(u => ({
          label: `${u.firstName} ${u.lastName}`,
          value: Number(u.id),
        }));
      });
  }

  /**
   * Fetches all projects and populates the dropdowns with the respective elements
   */
  private loadAllProjects(projectStatuses: PROJECT_STATUS[]): void {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyAllProjects')
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.projectSub = sub;
        this.projectDropdown =
          this.allProjectsService.makeLabelsArrayForStatuses(
            data,
            projectStatuses
          );
        this.projectDropdown.shift();
      });
  }

  private getProjectIdsFromDays(): number[] {
    return [...this.days, ...this.leaveDays]
      .map(d => d.projectId)
      .reduce((acc, val) => {
        if (!acc.includes(val)) {
          acc.push(val);
        }
        return acc;
      }, []);
  }

  private getMultiProjectProducts(): void {
    this.sectionLoading = true;
    this.multiProjectProductsSub && this.multiProjectProductsSub.unsubscribe();

    const projectIdsFromDays = this.getProjectIdsFromDays();

    const variables = {
      userIds: this.users,
      fromDate: this.dateRange.from,
      toDate: this.dateRange.to,
      projectIds: projectIdsFromDays,
    };

    this.apolloQueryService
      .apolloWatchQueryTwo('multiProjectProducts', variables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ data, sub }) => {
        this.multiProjectProductsSub = sub;
        this.projectProductsDataSetCount = 0;
        this.projectProductsDataSet = this.helperService.cleanFromNode(
          data['company']['projects']
        );
        this.projectProductsDataSet.forEach(project => {
          this.projectProductsDataSetCount +=
            project.projectProducts.edges.length;
        });

        this.projectProductsDataSet = this.flattenProductsDataSet(
          this.projectProductsDataSet
        );

        this.sectionLoading = false;
      });
  }

  private getMultiProjectComments(): void {
    this.sectionLoading = true;

    const projectIdsFromDays = this.getProjectIdsFromDays();

    const variables = {
      userIds: this.users,
      projectIds: projectIdsFromDays,
    };

    this.projectCommentsSub && this.projectCommentsSub.unsubscribe();

    this.apolloQueryService
      .apolloWatchQueryTwo('multiProjectComments', variables)
      .pipe(takeUntil(this.destroy$))
      .subscribe(data => {
        this.projectCommentsSub = data.sub;
        this.projectCommentsDataSetCount = 0;
        this.projectCommentsDataSet = this.helperService.cleanFromNode(
          data.data['company']['projects']
        );
        this.projectCommentsDataSet.forEach(project => {
          this.projectCommentsDataSetCount += project.comments.edges.length;
        });

        this.projectCommentsDataSet = this.flattenCommentsDataSet(
          this.projectCommentsDataSet
        );

        this.sectionLoading = false;
      });
  }

  private flattenCommentsDataSet(projectCommentsDataSet) {
    return projectCommentsDataSet.map(project => {
      project = JSON.parse(JSON.stringify(project));
      return project.comments.edges.map(comment => ({
        ...comment.node,
        userName: this.getUserName(comment.node),
        projectTrueId: project.trueId,
        projectMark: project.mark,
      }));
    });
  }

  private flattenProductsDataSet(projectProductsDataSet) {
    return projectProductsDataSet.map(project => {
      project = JSON.parse(JSON.stringify(project));
      return project.projectProducts.edges.map(product => ({
        ...product.node,
        userName: this.getUserName(product.node),
        projectTrueId: project.trueId,
        projectMark: project.mark,
      }));
    });
  }

  private getUserName(node: any): string {
    return (
      (node.user &&
        `${node.user.firstName || ''} ${node.user.lastName || ''}`) ||
      ''
    );
  }

  private getCompanyFunctionsThisModel(): void {
    for (const companyFunction in this.checkForCompanyFunctions) {
      const functionName = this.checkForCompanyFunctions[companyFunction];
      if (this.companyFunctionsService.companyFunctionIsSet(functionName)) {
        this.functionsThisModel[functionName] = true;
      } else {
        this.functionsThisModel[functionName] = false;
      }
    }
  }

  private getFunctionsData(): void {
    this.loadUserCostTypes();
  }

  private loadUserCostTypes(): void {
    this.fetchCompanyUserCostTypesGQL
      .fetch()
      .pipe(
        first(),
        map(r => r.data.company.userCostTypes.edges.map(e => e.node))
      )
      .subscribe(ucts => {
        ucts.forEach(uct => (this.userCostTypes[uct.id] = uct.name));
      });
  }

  public ngOnDestroy() {
    this.usersSub && this.usersSub.unsubscribe();
    this.projectSub && this.projectSub.unsubscribe();
    this.projectCommentsSub && this.projectCommentsSub.unsubscribe();
    this.multiProjectProductsSub && this.multiProjectProductsSub.unsubscribe();

    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
