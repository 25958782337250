import { Apollo } from 'apollo-angular';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Observable, Subject, BehaviorSubject, Subscription } from 'rxjs';
import { takeUntil, debounceTime, first } from 'rxjs/operators';

import { ConfirmationService } from 'primeng/api';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

import { ApolloQueryService, ApolloMutationService } from 'app/shared/index';
import { getRefetchQuerieOf, queries } from 'app/shared/apollo/queries/index';
import { FormHandlerService } from 'app/shared/forms';
import { MessageService } from 'app/shared/message';
import {
  CompanyInfo,
  CompanyInfoService,
  ContactsAutosuggestService,
} from 'app/shared/company';
import { HyperionLabelsService } from 'app/shared/labels';
import { SingleOfferService } from './single-offer.service';
import { CompanyUsersService, UserLocalStorageService } from 'app/shared/user';
import { GlobalService } from 'app/shared/global';
import { HttpService } from 'app/shared/http';
import { AppDialogService } from 'app/shared/dialogs';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { OfferRowHeaderInfo } from 'app/offer/single/offer-row-header-info';
import { ExternalProjectWithLabel } from 'app/shared/company/derome-integration/project-external-dropdown/external-project-with-label.interface';
import { ExternalProject } from 'app/shared/company/derome-integration/external-project.interface';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { GetContactByIdGQL } from './graphql/single-offer.generated';

const labelModels = [
  { model: 'Contact' },
  { model: 'User' },
  { model: 'Offer' },
  { model: 'UserCalculationRow' },
  { model: 'OfferSignatur' },
  { model: 'OfferContractor' },
  { model: 'OfferOrganization' },
  { model: 'Todo' },
];
const OFFER_TYPE_ATA = 'ata';
@Component({
  selector: 'app-single-offer',
  templateUrl: './single-offer.component.html',
  styleUrls: ['./single-offer.component.scss'],
  providers: [
    SingleOfferService,
    FormHandlerService,
    HttpService,
    AppDialogService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SingleOfferComponent implements OnInit, OnDestroy {
  @Input() offerMode: 'create' | 'created' | 'createdFromTemplate' | 'template';
  @Input() offerVariable;
  @Input() projectInfo;
  @Input() offerTodelete: Observable<any>;
  @Output() sideNavActions = new EventEmitter();
  @Output() triggerCreate = new EventEmitter();
  @Output() parentLoadingState = new EventEmitter();

  private componentDestroyed: Subject<void> = new Subject();
  public activeSection: BehaviorSubject<number> = new BehaviorSubject(-1);
  public getNewPdf: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public labels: BehaviorSubject<any> = new BehaviorSubject({});
  public loadingAsync: BehaviorSubject<boolean> = new BehaviorSubject(true);
  public rot: BehaviorSubject<{}> = new BehaviorSubject({});
  public showPreview: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public updating: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public offerHeaderRowInfo: OfferRowHeaderInfo;
  public currentExternalProject: ExternalProjectWithLabel;
  public isGreenTaxReduction = false;
  toggleForms = {
    client: new BehaviorSubject(false),
    establishment: new BehaviorSubject(false),
    comment: new BehaviorSubject(false),
    document: new BehaviorSubject(false),
  };
  showMoreFields = new BehaviorSubject(false);
  contactTypeModel = {
    client: { value: null, show: new BehaviorSubject(false) },
    establishment: { value: null, show: new BehaviorSubject(false) },
  };

  customSections = {
    inputForHourCost: [
      'inputForMachinHourCost',
      'inputForMileCost',
      'inputForTravelCost',
      'inputForMaterialProcent',
    ],
    insurance: ['phone', 'mail', 'validToDate'],
  };

  autoResults = {};
  autoSuggestModel = {};
  autoSearchString = {};
  offerForm: FormGroup;
  clientContactForm: FormGroup;
  establishmentContactForm: FormGroup;
  formFields = {
    model: 'Offer',
    attributes: {},
  };
  clientContactFormFields = {
    model: 'Contact',
    attributes: {},
  };
  establishmentContactFormFields = {
    model: 'Contact',
    attributes: {},
  };
  rotData = {
    rotPerson1: null,
    rotPerson2: null,
    rotSum: null,
    typeOffer: null,
  };
  offerAttr = [];
  signatures = [];
  contractors = [];
  organization = [];
  adminOptions = [];
  betalningsvillkorOptions = [
    { label: '0 dagar', value: 0 },
    { label: '10 dagar', value: 10 },
    { label: '15 dagar', value: 15 },
    { label: '20 dagar', value: 20 },
    { label: '30 dagar', value: 30 },
  ];
  contactTypes = [
    { label: 'Välj typ...', value: null },
    { label: 'Privat', value: 'Privat' },
    { label: 'Företag', value: 'Företag' },
  ];
  listValueEstablishmentForm = [
    'name',
    'address',
    'mail',
    'mobilePhone',
    'phone',
  ];
  listValueClientForm = [
    'orderBuisnessName',
    'name',
    'address',
    'mail',
    'mobilePhone',
    'phone',
  ];

  offer: {
    id?: string;
    name?: string;
    status?: number;
    type?: string;
    versions?: string[];
    isLocked?: boolean;
    typeOffer?: number;
  } = {};

  dataToChild: {
    id?: any;
    status: number;
    currentExternalProject?: string;
    typeOffer?: any;
    groupCosts?: any;
    calculation?: any;
    mode?: string;
    [key: string]: any;
  };
  totalComments: any = null;
  totalDocuments: any = null;
  pdfUrl: SafeUrl;

  @ViewChildren('section')
  sections: QueryList<ElementRef>;
  focusDetectDebounceTimeout = null;

  usersSub: Subscription;
  public showProductExternalConnectInterface = false;
  public showDeromeWebshopProducts = false;
  public hasDeromeWebshopProducts: boolean;
  public meUser: MeUserWithCompany;

  constructor(
    private angularApollo: Apollo,
    private apolloQueryService: ApolloQueryService,
    private companyInfoService: CompanyInfoService,
    private confirmationService: ConfirmationService,
    private contactsAutosuggestService: ContactsAutosuggestService,
    private dialogService: AppDialogService,
    private domSanitizer: DomSanitizer,
    private formHandler: FormHandlerService,
    private globalService: GlobalService,
    private httpService: HttpService,
    private labelsService: HyperionLabelsService,
    private mailDialogService: MailDialogService,
    private messageService: MessageService,
    private mutationService: ApolloMutationService,
    private singleOfferService: SingleOfferService,
    private userLocalStorageService: UserLocalStorageService,
    private usersService: CompanyUsersService,
    public dialogConfig: DynamicDialogConfig,
    public dialogRef: DynamicDialogRef,
    public router: Router,
    private getContactByIdService: GetContactByIdGQL
  ) {}

  ngOnInit() {
    if (this.dialogConfig['data']) {
      this.initComponentWithDialogData();
    }

    this.getLabels();

    this.setAdminOptions();

    if (
      this.offerMode === 'create' ||
      this.offerMode === 'createdFromTemplate'
    ) {
      this.toggleForms['client'].next(true);
      this.toggleForms['establishment'].next(true);
    }

    this.getSingleOffer(this.offerVariable);

    this.companyInfoService.companyInfo$.subscribe(
      (companyInfo: CompanyInfo) => {
        this.showProductExternalConnectInterface =
          companyInfo.showProductExternalConnectInterface;
        this.showDeromeWebshopProducts = companyInfo.hasDeromeWebshopActivated;
      }
    );

    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
  }

  trackSectionById = item => item.order;
  setTotalComments(val) {
    this.totalComments = val;
  }
  setTotalDocuments(val) {
    this.totalDocuments = val;
  }

  private initComponentWithDialogData() {
    this.offerMode = this.dialogConfig['data']['offerMode'];
    this.projectInfo = this.dialogConfig['data']['projectInfo'];
    this.offerVariable = this.dialogConfig['data']['offerVariable'];
    setTimeout(() => {
      this.dialogConfig.header = 'Skapa ny ÄTA';
    }, 0);
  }

  public actionsFromSideNav(actions: {
    name: string;
    value: any;
    variables;
    callCreate: boolean;
  }) {
    switch (actions['name']) {
      case 'update':
        this.actionUpdate(null, actions['variables']);
        break;
      case 'updateAndTriggerCreate':
        this.actionUpdate(
          null,
          actions['variables'],
          null,
          actions['callCreate']
        );
        break;
      case 'mailModule':
        this.showMailModal();
        break;
      default:
        this.sideNavActions.emit(actions);
    }
  }

  getLabels() {
    this.labelsService.getLabels(labelModels).subscribe(data => {
      this.labels.next(data);
    });
  }

  setAdminOptions() {
    this.apolloQueryService
      .apolloWatchQueryTwo('companyUsers', null, 'cache-and-network')
      .subscribe(({ data, sub }) => {
        this.usersSub = sub;
        this.adminOptions = this.usersService.makeLabelsArray(data);
      });
  }

  getSingleOffer(offerVariable) {
    this.parentLoadingState.emit(true);
    this.apolloQueryService
      .apolloQuery('singleOffer', offerVariable)
      .subscribe(({ data }) => {
        this.setFieldsAutoSuggest(data['offer']);
        this.setDataToChildComponents(data['offer']);

        this.pdfUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(
          '/offer/Print/type/showPDF/id/' + data['offer']['id']
        );

        this.loadingAsync.next(false);
        this.parentLoadingState.emit(false);
      });
  }

  setDataToChildComponents(offer) {
    this.setRotData(offer);
    this.setOfferObjectAndInitForm(offer);
    this.setOfferRowHeaderInfo(offer);
    this.signatures = this.apolloQueryService.cleanFromNode(offer['signaturs']);
    this.contractors = this.apolloQueryService.cleanFromNode(
      offer['contractors']
    );
    this.organization = this.apolloQueryService.cleanFromNode(
      offer['organization']
    );

    this.singleOfferService.addTodos(
      this.apolloQueryService.cleanFromNode(offer['todos'])
    );
    const allOfferRows = this.apolloQueryService.cleanFromNode(
      offer['calculationRows']
    );
    const offerRows = allOfferRows.filter(
      row => row.source !== 'derome-webshop'
    );
    const webshopRows = allOfferRows.filter(
      row => row.source === 'derome-webshop'
    );

    this.singleOfferService.addCalculationRows(offerRows);
    this.singleOfferService.addWebshopRows(webshopRows);

    this.currentExternalProject = this.getExternalProjectsId(offer);
    const currentExternalProject =
      (this.currentExternalProject &&
        this.currentExternalProject.value &&
        this.currentExternalProject.value.id) ||
      null;

    this.dataToChild = {
      id: offer['id'],
      currentExternalProject,
      typeOffer: offer['typeOffer'],
      groupCosts: offer['groupCosts'],
      calculation: {
        id: offer['calculation']['id'],
        deromeWebshopStatus: offer.calculation.deromeWebshopStatus,
      },
      status: offer.status,
      mode: this.offerMode,
    };

    if (this.projectInfo?.clientContact?.reverseTax) {
      this.setOfferToReverseTax();
    }
  }

  setOfferRowHeaderInfo(offer) {
    const attributesWithMeta =
      offer.attributesWithMeta_PreOfferTypeHyperion.edges;
    const concerningNode = attributesWithMeta.find(
      node => node.node.name === 'concerning'
    );
    const marking =
      (concerningNode && concerningNode.node && concerningNode.node.value) ||
      '';

    this.offerHeaderRowInfo = {
      trueId: offer.trueId,
      marking: marking,
      clientCompany:
        (offer.clientContact && offer.clientContact.orderBuisnessName) || null,
      clientName: (offer.clientContact && offer.clientContact.name) || null,
    };
  }

  setOfferObjectAndInitForm(offer) {
    this.offer = {
      id: offer['id'],
      name: offer['templateName'],
      status: offer['status'],
      type: offer['type'],
      versions: this.apolloQueryService.cleanFromNode(offer['versions']),
      isLocked: offer['isLocked'],
    };
    this.initForm(offer);
  }

  setRotData(offer) {
    const rotDataSet = {
      rotPerson1: offer['rotPerson1'],
      rotPerson2: offer['rotPerson2'],
      rotSum: offer['rotSum'],
      rotYear: offer['rotYear'],
    };
    this.rotData['rotPerson1'] = offer['rotPerson1'];
    this.rotData['rotPerson2'] = offer['rotPerson2'];
    this.rotData['rotSum'] = offer['rotSum'];
    this.rotData['rotYear'] = offer['rotYear'];
    this.rot.next(rotDataSet);
  }

  toggleForm(panelNameParam, value = null) {
    if (value === true || value === false) {
      this.toggleForms[panelNameParam].next(value);
    } else {
      this.toggleForms[panelNameParam].next(
        !this.toggleForms[panelNameParam].value
      );
    }
  }

  initForm(offerData: any): void {
    this.formFields['attributes'] = this.setFieldsForOfferForm(offerData);

    const clientContactInformation = { ...offerData['clientContact'] };
    const establishmentContactInformation = {
      ...offerData['establishmentContact'],
    };

    for (const key in clientContactInformation) {
      if (clientContactInformation.hasOwnProperty(key)) {
        this.clientContactFormFields['attributes'][key] =
          clientContactInformation[key] ? clientContactInformation[key] : null;
      }
    }

    for (const key in establishmentContactInformation) {
      if (establishmentContactInformation.hasOwnProperty(key)) {
        this.establishmentContactFormFields['attributes'][key] =
          establishmentContactInformation[key]
            ? establishmentContactInformation[key]
            : null;
      }
    }

    delete this.clientContactFormFields['attributes']['__typename'];
    delete this.establishmentContactFormFields['attributes']['__typename'];

    this.offerForm = this.formHandler.groupedFormSimple(this.formFields);
    this.formHandler
      .groupSetLabelsRules(this.formFields, this.offerForm)
      .then();

    this.clientContactForm = this.formHandler.groupedFormSimple(
      this.clientContactFormFields
    );
    this.formHandler.groupSetLabelsRules(
      this.clientContactFormFields,
      this.clientContactForm
    );

    this.establishmentContactForm = this.formHandler.groupedFormSimple(
      this.establishmentContactFormFields
    );
    this.formHandler
      .groupSetLabelsRules(
        this.establishmentContactFormFields,
        this.establishmentContactForm
      )
      .then();

    this.subscribeToFormValueChanges();
  }

  subscribeToFormValueChanges() {
    this.clientContactForm.valueChanges
      .pipe(debounceTime(5000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.actionUpdate('client');
      });

    this.establishmentContactForm.valueChanges
      .pipe(debounceTime(5000), takeUntil(this.componentDestroyed))
      .subscribe(() => {
        this.actionUpdate('establishment');
      });
  }

  setFieldsForOfferForm(offerData) {
    offerData = JSON.parse(JSON.stringify(offerData));

    const fields = {};
    const fieldsArray = [];
    const staticFields = [
      'headerText',
      'footerText',
      'inputForHourCost',
      'inputForMachinHourCost',
      'inputForMileCost',
      'inputForTravelCost',
      'inputForMaterialProcent',
      'insurance',
      'phone',
      'mail',
      'validToDate',
      'templateName',
      'sendFromUserId',
    ]; // used for field that are not dynamic

    const attributesWithMeta =
      offerData['attributesWithMeta_PreOfferTypeHyperion']['edges'];

    // Setting static fields to form
    for (const index in staticFields) {
      if (staticFields.hasOwnProperty(index)) {
        fields[staticFields[index]] = offerData[staticFields[index]];
      }
    }

    // adding dynamic fields to form backend
    for (let index = 0; index < attributesWithMeta.length; index++) {
      const node = attributesWithMeta[index]['node'];
      node['order'] === null && (node['order'] = index + 1); // for side navigation on create
      if (node['type'] === 'offerAttribute') {
        fields[node['name']] =
          this.offerMode === 'create' && node['addAsExempel']
            ? node['exampelText']
            : node['value'];
      }
      if (typeof node['type'] !== 'undefined') {
        fieldsArray.push(node);
      }
    }

    fields['id'] = +offerData['id'];

    this.offer['offerAttr'] = [...fieldsArray];

    return fields;
  }

  public updateOfferAttr(attrs: any[]): void {
    this.offer['offerAttr'] = attrs;
  }

  public makeSectionActive(rowOrderAsId: number): void {
    const newAction = this.offer['offerAttr'].map((offer, index) => {
      if (offer['order'] !== rowOrderAsId) {
        return offer;
      }
      return { ...offer, active: 1 };
    });
    this.offer['offerAttr'] = newAction;
    this.messageService.insertData({
      textArray: [
        'Du har valt ett textblock som kommer visas på offerten, du kan välja bort det till höger.',
      ],
      time: 2000,
      type: 'success',
    });
  }

  public updateSectionStates(rowOrderAsId: number, event: any, action): void {
    const newAction = this.offer['offerAttr'].map(offer => {
      if (offer['order'] !== rowOrderAsId) {
        return offer;
      }
      return { ...offer, [action]: +event.checked, active: 1 };
    });
    this.offer['offerAttr'] = newAction;
  }

  copyContactToEstablishment() {
    for (const key in this.establishmentContactForm.value) {
      if (this.clientContactForm.controls.hasOwnProperty(key) && key !== 'id') {
        this.establishmentContactForm.controls[key].setValue(
          this.clientContactForm['value'][key]
        );
      }
    }
    this.actionUpdate('establishment');
  }

  public actionUpdate(
    modelName = null,
    variables = null,
    reloadPdf = null,
    callCreate = false
  ) {
    // modelName used o get data from form => when model name is null, then it is automatically offer
    const form = this.getForm(modelName);
    const model = modelName ? 'Contact' : 'Offer';

    const shouldNavigateAfterQuery =
      variables && variables['navigate'] ? true : false;
    variables && variables['navigate'] && delete variables['navigate'];
    let dataToMutation = variables
      ? { ...this.formHandler.getCleanedMutationVariable(form), ...variables }
      : this.formHandler.getCleanedMutationVariable(form);

    if (model === 'Offer') {
      dataToMutation = this.appendDataToMutateObj(dataToMutation);
    }

    if (variables && variables['type'] === 'ata' && variables['status'] === 1) {
      dataToMutation['client'] = this.projectInfo['client'];
      dataToMutation['establishment'] = this.projectInfo['establishment'];
      dataToMutation['projectId'] = this.projectInfo['id'];
    }

    if (this.formHandler.formValid([form])) {
      const refetchArr =
        model === 'Contact'
          ? Object.keys(form.value)
          : [getRefetchQuerieOf('companyOffers')];

      // If autocomplete contact search is not connected to a contact
      if (
        (!dataToMutation['trueId'] || dataToMutation['trueId'] === 0) &&
        model === 'Contact'
      ) {
        dataToMutation.contactParentId = null;
      }

      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          model,
          'update',
          false,
          dataToMutation,
          refetchArr
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            executeMutationSub.unsubscribe();

            if (executedData.mutationSucceededAllArguments) {
              reloadPdf && this.setNewPdf();

              callCreate && this.triggerCreate.emit(executedData['id']);

              if (!shouldNavigateAfterQuery && variables) {
                this.singleOfferService.changeUpdateRights(true);
              }

              if (
                shouldNavigateAfterQuery ||
                (variables && variables['type'] === 'ata')
              ) {
                this.updateStore(
                  dataToMutation,
                  executedData,
                  shouldNavigateAfterQuery
                );
              }
            }
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  private getForm(modelName: string): FormGroup {
    if (modelName === 'client') {
      return this.clientContactForm;
    }
    if (modelName === 'establishment') {
      return this.establishmentContactForm;
    }
    return this.offerForm;
  }

  appendDataToMutateObj(dataToMutation): any {
    dataToMutation['offerFields'] = this.arrayToObject(this.offer['offerAttr']);
    for (const key in this.rotData) {
      if (this.rotData.hasOwnProperty(key)) {
        dataToMutation[key] = this.rotData[key];
      }
    }
    return dataToMutation;
  }

  public updateStore(vars, response, shouldNavigateAfterQuery) {
    const objToAppend = { ...response };
    if (!shouldNavigateAfterQuery) {
      this.dialogRef.close({ name: 'newAtaCreated' });
      objToAppend['projectId'] = +this.projectInfo['id'];
    }
    delete objToAppend['mutationDetails'];
    delete objToAppend['mutationSucceededAllArguments'];

    const queryParams = {
      query: null,
      variables: null,
    };

    if (this.offer['type'] === OFFER_TYPE_ATA) {
      queryParams.query = queries['projectAtas'];
      queryParams.variables = {
        id: this.projectInfo['id'],
      };
    } else {
      queryParams.query = queries['companyOffers'];
      queryParams.variables = {
        status: [vars['status']],
      };
    }

    try {
      const storedDataByQuery = this.angularApollo
        .getClient()
        .readQuery(queryParams);

      if (this.offer['type'] === OFFER_TYPE_ATA) {
        storedDataByQuery['company']['projects']['edges'][0]['node']['atas'][
          'totalCount'
        ] =
          storedDataByQuery['company']['projects']['edges'][0]['node']['atas'][
            'totalCount'
          ] + 1;
        storedDataByQuery['company']['projects']['edges'][0]['node']['atas'][
          'edges'
        ].push({ node: objToAppend, __typename: 'OfferEdge' });
      } else {
        storedDataByQuery['company']['offers']['totalCount'] =
          storedDataByQuery['company']['offers']['totalCount'] + 1;
        storedDataByQuery['company']['offers']['edges'].push({
          node: objToAppend,
          __typename: 'OfferEdge',
        });
      }

      this.angularApollo.getClient().writeQuery({
        query: queryParams.query,
        variables: queryParams.variables,
        data: storedDataByQuery,
      });
    } catch {
      console.warn(
        'GQL error single-offer update store, Checks: Make sure the store you are trying to update has been popluated by visiting the link first '
      );
    } finally {
      if (shouldNavigateAfterQuery) {
        this.router.navigateByUrl(
          '/offer/index/' + vars['status'] + '?id=' + objToAppend['id']
        );
      }
    }
  }

  searchContacts(event, model) {
    this.contactsAutosuggestService.getContacts(event.query).then(data => {
      this.messageService.insertDataPOSTGET(data);
    });
  }

  public setContactFromAutosuggest(
    id: number,
    model: 'client' | 'establishment'
  ): void {
    this.getContactByIdService
      .fetch({ id: id })
      .pipe(first())
      .subscribe(res => {
        const contact = res.data.company.contacts.edges[0].node;

        if (contact.reverseTax) {
          this.setOfferToReverseTax();
        }

        this.setContact(contact, model);
      });
  }

  private setOfferToReverseTax(): void {
    this.rotData = {
      ...this.rotData,
      typeOffer: 2,
    };

    this.dataToChild = {
      ...this.dataToChild,
      typeOffer: 2,
    };

    if (this.offer) {
      this.offer = {
        ...this.offer,
        typeOffer: 2,
      };
    }
  }

  private setContact(xx = {}, model: 'client' | 'establishment'): void {
    let form: FormGroup;
    const test = JSON.stringify(xx);
    const value = JSON.parse(test);

    if (model === 'client') {
      form = this.clientContactForm;
    } else {
      form = this.establishmentContactForm;
    }
    for (const key in value) {
      if (value.hasOwnProperty(key)) {
        const valueVal = value[key];
        if (key !== 'id' && form['controls'].hasOwnProperty(key)) {
          form['controls'][key].setValue(valueVal);
        }
      }
    }

    this.actionUpdate(model);
    this.contactTypeModel[model]['show'].next(false);
  }

  ngOnDestroy(): void {
    this.componentDestroyed.next();
    this.componentDestroyed.complete();
    this.usersSub && this.usersSub.unsubscribe();
  }

  handleNewRotData(eventParam) {
    const newRotData = eventParam;
    this.rotData = { ...newRotData };

    if (this.rotData.typeOffer === '5') {
      this.isGreenTaxReduction = true;
    } else {
      this.isGreenTaxReduction = false;
    }
  }

  setFieldsAutoSuggest(offerData) {
    const attributesWithMeta =
      offerData['attributesWithMeta_PreOfferTypeHyperion']['edges'];

    for (const index in attributesWithMeta) {
      if (attributesWithMeta.hasOwnProperty(index)) {
        const node = attributesWithMeta[index]['node'];
        if (node['autoSuggest']) {
          this.autoResults[node['name']] = new BehaviorSubject([]);
          this.autoSuggestModel[node['name']] = null;
          if (node['inputType'] === 'textArea') {
            this.autoSearchString[node['name']] = null;
          }
        }
      }
    }
  }

  autoCompleteByFieldSearch(event, fieldName) {
    this.autoCompleteHttpCall(event, fieldName).then(data => {
      const result = [];
      for (const i in data) {
        if (data.hasOwnProperty(i)) {
          result.push(data[i]);
        }
      }
      this.autoResults[fieldName].next([...result]);
    });
  }

  autoCompleteHttpCall(event, fieldName) {
    const url =
      this.globalService.getUrlPrefix() +
      '/offer/AutoCompleteByField?q=' +
      event.query +
      '&limit=10&field=' +
      fieldName;
    return this.httpService.makeHttpGetRequest(url).then(({ data }) => data);
  }

  private showMailModal(): void {
    this.mailDialogService.openMailDialog({
      offerId: this.offerVariable['id'],
      email: this.clientContactForm['controls']['mail']['value'],
    });
  }

  setNewPdf() {
    this.getNewPdf.next(true);
    setTimeout(() => {
      this.getNewPdf.next(false);
    }, 500);
  }

  private arrayToObject(array) {
    return array.reduce((obj, item) => {
      obj[item.name] = {
        order: item.order,
        active: item.active ? item.active : 0,
        breakpage: item.breakpage ? item.breakpage : 0,
        forceBreakpage: item.forceBreakpage ? item.forceBreakpage : 0,
      };
      return obj;
    }, {});
  }

  @HostListener('window:scroll')
  public detectFocusedSection(): void {
    if (this.focusDetectDebounceTimeout === null) {
      this.focusDetectDebounceTimeout = setTimeout(() => {
        let relPx = window.scrollY;
        if (this.offerMode === 'created') {
          const OfferElement = document
            .getElementById('offer_container_' + this.offerVariable['id'])
            .getBoundingClientRect();
          relPx = Math.abs(OfferElement.top);
        }

        const newFocused = this.sections.find(
          section =>
            section.nativeElement.offsetTop +
              section.nativeElement.offsetHeight / 2 >
            relPx
        );

        if (newFocused !== undefined) {
          this.activeSection.next(
            Number(newFocused.nativeElement.dataset.rowId)
          );
        }

        this.focusDetectDebounceTimeout = null;
      }, 50);
    }
  }

  scrollToSection(rowId) {
    document.getElementById(`offer-edit-section-${rowId}`).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }

  confirmCreateContact(model) {
    this.confirmationService.confirm({
      message: 'Spara som ny post i företagets kontaktlista?',
      header: 'Bekräfta val',
      icon: 'fa fa-save',
      accept: () => {
        this.createContact(model);
      },
      reject: () => {},
    });
  }

  private createContact(model: string) {
    const form = this.getForm(model);
    const dataToMutation = this.formHandler.getCleanedMutationVariable(form);
    if (this.formHandler.formValid([form])) {
      const returnValues = ['id', 'trueId'];
      dataToMutation['contact'] = 1;
      dataToMutation['contactType'] = this.contactTypeModel[model]['value'];
      const executeMutationSub = this.mutationService
        .constructQueryAndExecuteMutation(
          'contact',
          'create',
          false,
          dataToMutation,
          returnValues
        )
        .subscribe(
          executedData => {
            this.mutationService.displayMutationStatus(executedData);
            if (executedData.mutationSucceededAllArguments) {
              form['controls']['trueId'].setValue(executedData['trueId']);
              this.contactTypeModel[model]['value'] = null;
              this.contactTypeModel[model]['show'].next(false);
            }
            executeMutationSub.unsubscribe();
          },
          err => {
            console.log(err);
          }
        );
    }
  }

  public setHasDeromeWebshopProducts(hasProducts: boolean): void {
    this.hasDeromeWebshopProducts = hasProducts;
  }

  public refreshOffer(): void {
    this.getSingleOffer(this.offerVariable);
  }

  /**
   * To avoid needing a reload this method is called to update prices when
   * a new external project is connected.
   *
   * @param externalProject the external project the offer has been connected to.
   */
  public updatePrices(externalProject: ExternalProject) {
    this.getSingleOffer(this.offerVariable);
  }

  private getExternalProjectsId(offer: any): ExternalProjectWithLabel {
    if (
      !offer ||
      !offer.externalIds ||
      !offer.externalIds.edges ||
      !offer.externalIds.edges.length
    ) {
      return null;
    }

    const externalProjectsId = this.apolloQueryService.cleanFromNode(
      offer.externalIds
    )[0];

    return (
      (externalProjectsId && {
        label: `${externalProjectsId.sourceId} - ${externalProjectsId.name}`,
        value: externalProjectsId,
      }) ||
      null
    );
  }

  public removeParent(form: FormGroup): void {
    const oldValue = form.value;

    const newValue = { ...oldValue, trueId: '' };

    form.patchValue(newValue);
  }
}
