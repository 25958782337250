import { gql } from 'apollo-angular';

import { User } from './user';

// GQL FRAGMENTS

const DAY_FRAGMENT_COMMON = gql`
  fragment DayCommon on Day {
    id
    costTypeId
    date
    doneWork
    extra
    hours
    hoursToInvoice
    invoiceId
    mile
    mileToInvoice
    payslipId
    privMile
    timeHour
    isStartstop
  }
`;

const DAY_FRAGMENT_USERS = gql`
  fragment DayUsers on Day {
    ...DayCommon
    overtimeHardCoded
    subsistenceDay
    subsistenceHalfDay
    subsistenceNight
    startTime
    stopTime
    userId
    projectId
    costTypeHyperion {
      id
      companyCostTypeId
    }
    projectId
    project {
      id
      trueId
      mark
      status
      todos {
        edges {
          node {
            id
            description
          }
        }
      }
    }
    user {
      ...UserNameFull
    }
    todoId
  }
  ${User.fragments.fullName}
  ${DAY_FRAGMENT_COMMON}
`;

/** Export GQL Fragments on Day */
export const Day = {
  fragments: {
    dayCommon: DAY_FRAGMENT_COMMON,
    dayUsers: DAY_FRAGMENT_USERS,
  },
};

// GQL QUERIES

const SINGLE_PROJECTS_DAYS_QUERY = {
  query: gql`
    query fetchSingleProjectDays($projectId: Int) {
      project(id: $projectId) {
        id
        availableForTimeReport
        days {
          edges {
            node {
              ...DayCommon
              created
              notarized
              subsistenceDay
              subsistenceHalfDay
              subsistenceNight
              todoId
              notarizedUser {
                ...UserNameFull
              }
              user {
                ...UserNameFull
              }
              costTypeHyperion {
                id
                companyCostTypeId
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
    ${Day.fragments.dayCommon}
  `,
  testVariables: {
    projectId: 1906,
  },
};

const SINGLE_PROJECT_PRODUCTS = {
  query: gql`
    query fetchSingleProjectProducts($projectId: Int) {
      company {
        id
        projects(id: $projectId) {
          edges {
            node {
              projectProducts {
                edges {
                  node {
                    id
                    date
                    artnr
                    benamning
                    created
                    invoiceId
                    avtalspris
                    totalAmount
                    projectCostTypeId
                    antal
                    extra
                    source
                    sourceId
                    user {
                      ...UserNameFull
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    projectId: 1906,
  },
};

const SINGLE_PROJECT_TODO_DAYS = {
  query: gql`
    query fetchSingleProjectTodoDays($projectId: Int) {
      project(id: $projectId) {
        id
        days {
          edges {
            node {
              id
              hours
              todoId
              user {
                ...UserNameFull
              }
            }
          }
        }
      }
    }
    ${User.fragments.fullName}
  `,
  testVariables: {
    projectId: 1906,
  },
};

const MULTI_PROJECT_PRODUCTS = {
  query: gql`
    query multiProjectProducts(
      $projectIds: [Int]
      $fromDate: String
      $toDate: String
      $userIds: [Int]
    ) {
      company {
        id
        projects(multiId: $projectIds) {
          edges {
            node {
              id
              trueId
              mark
              projectProducts(
                fromDate: $fromDate
                toDate: $toDate
                userId: $userIds
              ) {
                totalCount
                edges {
                  node {
                    id
                    date
                    benamning
                    userId
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
  testVariables: {
    projectIds: [1906],
    userIds: [2],
    fromDate: null,
    toDate: null,
  },
};

const TIME_REPORT = {
  query: gql`
    query timeReport(
      $userId: [Int]
      $projectIds: [Int]
      $fromDate: String
      $toDate: String
    ) {
      company {
        id
        daysAll(
          userId: $userId
          projectIds: $projectIds
          fromDate: $fromDate
          toDate: $toDate
        ) {
          totalCount
          edges {
            node {
              ...DayUsers
            }
          }
        }
      }
    }
    ${Day.fragments.dayUsers}
  `,
  testVariables: {
    userId: [2],
    projectId: 1906,
    fromDate: null,
    toDate: null,
  },
};

const DAYS_SETTINGS = {
  query: gql`
    query fetchDaysSettingsQuery {
      company {
        id
        daysToReportTime
        showSubsistenceOnTimereport
      }
    }
  `,
};

/** EXPORTS */

export const queries = {
  singleProjectDays: SINGLE_PROJECTS_DAYS_QUERY,
  singleProjectProducts: SINGLE_PROJECT_PRODUCTS,
  singleProjectTodoDays: SINGLE_PROJECT_TODO_DAYS,
  multiProjectProducts: MULTI_PROJECT_PRODUCTS,
  timeReport: TIME_REPORT,
  daysSettings: DAYS_SETTINGS,
};
