<div class="p-grid">
  <div class="p-col-12">
    <p-treeTable
      #treeTable
      [value]="timeReports"
      [columns]="getColumns()"
      [lazy]="true"
      [paginator]="totalCount > pageSize"
      [rows]="pageSize"
      [totalRecords]="totalCount"
      (onLazyLoad)="fetchTimeReportHeaders($event)"
      (onNodeExpand)="loadTimeReportsForDate($event)"
      (onNodeCollapse)="recheckOpenedNodes()"
      class="inline-table"
      [class.scrollable]="!isExpanded"
      [class.scrollable-fullscreen]="isExpanded"
      (onEditInit)="setEditing(true)"
      (onEditComplete)="setEditing(false)"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [style.width]="col.width"
            [class]="col.icon ? 'text-cell-center' : 'p-text-truncate'"
          >
            <span *ngIf="col.field !== 'date'">
              {{ col.header }}

              <i
                [pTooltip]="col.tooltip"
                *ngIf="col.icon"
                [class]="col.icon"
              ></i>
            </span>

            <span *ngIf="col.field === 'date'" class="p-d-flex p-jc-between">
              {{ col.header }}
              <button
                *ngIf="col.field === 'date' && !loadingTimeReports"
                class="p-link"
                [style]="{ fontSize: '1.1rem' }"
                (click)="toggleAll($event)"
              >
                <i
                  class="pi pi-angle-double-right"
                  *ngIf="!areTimereportsExpanded"
                ></i>
                <i
                  class="pi pi-angle-double-down"
                  *ngIf="areTimereportsExpanded"
                ></i>
              </button>
              <i class="pi pi-spin pi-spinner" *ngIf="loadingTimeReports"></i>
            </span>
          </th>
        </tr>
      </ng-template>
      <ng-template
        pTemplate="body"
        let-rowNode
        let-rowData="rowData"
        let-columns="columns"
        let-expanded="expanded"
      >
        <tr *ngIf="rowData">
          <ng-container *ngFor="let col of columns; let i = index">
            <td
              *ngIf="col.field === 'doneWork'"
              [ttEditableColumn]="rowData[col.field]"
              [ttEditableColumnField]="col.field"
              [ttEditableColumnDisabled]="rowData.isFullDayInvoiced"
              [style.whiteSpace]="'break-spaces'"
            >
              <p-treeTableCellEditor (change)="actionUpdateDay(rowData)">
                <ng-template pTemplate="input">
                  <textarea
                    pInputTextarea
                    appDisableArrowNavigation
                    (keydown.enter)="actionUpdateDay(rowData)"
                    [(ngModel)]="rowData[col.field]"
                  ></textarea>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-d-flex p-jc-between p-ai-center">
                    {{ rowData[col.field] }}

                    <button
                      pButton
                      *ngIf="!rowData.isHeader"
                      class="p-button-text legacy-rounded-button"
                      icon="pi pi-arrow-up"
                      (click)="
                        addTextToParent(rowData.doneWork, rowData.parentId)
                      "
                      pTooltip="Lägg till text i underlag"
                    ></button>
                  </span>
                </ng-template>
              </p-treeTableCellEditor>
            </td>
            <td
              *ngIf="
                [
                  'hoursToInvoice',
                  'hours',
                  'mileToInvoice',
                  'mile',
                  'privMile',
                  'overtimeHardCoded',
                  'companyCostTypeId'
                ].includes(col.field)
              "
              [ttEditableColumn]="rowData[col.field]"
              [ttEditableColumnField]="col.field"
              [ttEditableColumnDisabled]="
                rowData.isDayInvoiced || rowData.isHeader
              "
            >
              <p-treeTableCellEditor
                (change)="actionUpdateDay(rowData)"
                *ngIf="col.field === 'companyCostTypeId'"
              >
                <ng-template pTemplate="output">
                  {{ rowData.companyCostType?.name }}
                </ng-template>
                <ng-template pTemplate="input">
                  <p-dropdown
                    appDisableArrowNavigation
                    [options]="userCosttypeDropdown"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="rowData.companyCostTypeId"
                    appendTo="body"
                    (onChange)="actionUpdateDay(rowData)"
                  ></p-dropdown>
                </ng-template>
              </p-treeTableCellEditor>

              <p-treeTableCellEditor
                (change)="actionUpdateDay(rowData)"
                *ngIf="col.field === 'overtimeHardCoded'"
              >
                <ng-template pTemplate="output">
                  {{ rowData.attendanceType?.name }}
                </ng-template>
                <ng-template pTemplate="input">
                  <p-dropdown
                    appDisableArrowNavigation
                    [options]="attendanceTypeDropdown"
                    optionLabel="label"
                    optionValue="value"
                    [(ngModel)]="rowData.attendanceTypeId"
                    appendTo="body"
                    (onChange)="actionUpdateDay(rowData)"
                  ></p-dropdown>
                </ng-template>
              </p-treeTableCellEditor>
              <p-treeTableCellEditor
                (change)="actionUpdateDay(rowData)"
                *ngIf="
                  [
                    'hoursToInvoice',
                    'hours',
                    'mileToInvoice',
                    'mile',
                    'privMile'
                  ].includes(col.field)
                "
              >
                <ng-template pTemplate="input">
                  <textarea
                    pInputTextarea
                    appDisableArrowNavigation
                    (keydown.enter)="actionUpdateDay(rowData)"
                    [(ngModel)]="rowData[col.field]"
                  ></textarea>
                </ng-template>
                <ng-template pTemplate="output">
                  <span class="p-d-flex p-jc-between p-ai-center">
                    {{ rowData[col.field] | number: '1.0-2' }}
                  </span>
                </ng-template>
              </p-treeTableCellEditor>
            </td>
            <td
              *ngIf="
                ![
                  'hoursToInvoice',
                  'hours',
                  'mileToInvoice',
                  'mile',
                  'privMile',
                  'overtimeHardCoded',
                  'companyCostTypeId',
                  'doneWork'
                ].includes(col.field)
              "
              [style.whiteSpace]="'break-spaces'"
              [class]="
                col.field !== 'invoiceId' &&
                col.field !== 'todoId' &&
                col.field !== 'notarized' &&
                col.field !== 'actionMenu'
                  ? 'p-text-truncate'
                  : ''
              "
            >
              <span
                *ngIf="col.field === 'date' && rowData.isHeader"
                class="p-text-bold p-d-flex p-jc-between"
                [style.cursor]="'pointer'"
              >
                {{ rowData[col.field] }}
                <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
              </span>
              <span
                *ngIf="col.field === 'date' && !rowData.isHeader"
                class="p-d-flex p-jc-between"
              >
                <span>{{ rowData[col.field] }}</span>
                <span *ngIf="rowData.hasParent">
                  <i
                    class="pi pi-link"
                    pTooltip="Länkad till en annan tidrapport"
                  ></i>
                </span>
                <span *ngIf="rowData.hasProducts">
                  <i
                    class="pi pi-shopping-cart"
                    pTooltip="Tidrapporten har kostnader kopplade"
                  ></i>
                </span>
              </span>

              <span *ngIf="col.field === 'invoiceId'">
                <i
                  *ngIf="rowData.isDayInvoiced"
                  class="pi pi-check"
                  style="color: var(--btn-success-color)"
                  [style.cursor]="rowData.invoiceId ? 'pointer' : ''"
                  [routerLink]="
                    rowData.invoiceId
                      ? ['../../../', 'invoice', rowData.invoiceId]
                      : []
                  "
                  [pTooltip]="rowData.invoiceId ? 'Visa relaterad faktura' : ''"
                ></i>
              </span>
              <span *ngIf="col.field === 'todoId'">
                <i
                  *ngIf="rowData.todoId"
                  class="pi pi-link"
                  [style.cursor]="rowData.todoId ? 'pointer' : ''"
                  [routerLink]="['../', 'worktasks']"
                  [pTooltip]="
                    rowData.todoId ? todoMap[rowData.todoId]?.description : ''
                  "
                ></i>
              </span>
              <span *ngIf="col.field === 'notarized'">
                {{ rowData.notarizedBy }}
              </span>
              <ng-container *ngIf="col.field === 'actionMenu'">
                <p-menu
                  appendTo="body"
                  #menuFullDay
                  [popup]="true"
                  [model]="actionMenus"
                ></p-menu>
                <button
                  *ngIf="!rowData.isSaving && rowData.isHeader"
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-ellipsis-v"
                  (click)="
                    selectedDayEditId = null;
                    selectedDayEdit = rowData;
                    generateActionsMenu(rowNode.node);
                    menuFullDay.toggle($event)
                  "
                ></button>
                <button
                  *ngIf="!rowData.isSaving && !rowData.isHeader"
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-ellipsis-v"
                  (click)="
                    selectedDayEdit = null;
                    selectedDayEditId = rowData.id;
                    generateActionsMenu(rowNode.node);
                    menuFullDay.toggle($event)
                  "
                ></button>
                <button
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-spinner pi-spin"
                  *ngIf="rowData.isSaving"
                ></button>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="paginatorfirstpagelinkicon">
        <AngleDoubleLeftIcon
          [styleClass]="'p-paginator-icon'"
        ></AngleDoubleLeftIcon>
      </ng-template>
      <ng-template pTemplate="paginatorpreviouspagelinkicon">
        <AngleLeftIcon [styleClass]="'p-paginator-icon'"></AngleLeftIcon>
      </ng-template>
      <ng-template pTemplate="paginatornextpagelinkicon">
        <AngleRightIcon [styleClass]="'p-paginator-icon'"></AngleRightIcon>
      </ng-template>
      <ng-template pTemplate="paginatorlastpagelinkicon">
        <AngleDoubleRightIcon
          [styleClass]="'p-paginator-icon'"
        ></AngleDoubleRightIcon>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [colSpan]="2">
            <div class="p-d-flex p-jc-between p-ai-center">
              <span> Visa fullständig summering </span>
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-plus"
                class="p-button-rounded p-button-outlined legacy-rounded-button"
                (click)="toggleShowFullSummary()"
              ></button>
            </div>
          </td>
          <td class="p-text-bold" *ngIf="isExpanded">
            {{ fieldsSummary.hours | number: '1.2-2' }}
          </td>
          <td class="p-text-bold">
            {{ fieldsSummary.hoursToInvoice | number: '1.2-2' }}
          </td>
          <td class="p-text-bold" *ngIf="isExpanded && setMile && setPrivMile">
            {{ fieldsSummary.privMile | number: '1.0-2' }}
          </td>
          <td class="p-text-bold" *ngIf="setMile">
            {{ fieldsSummary.mileToInvoice | number: '1.0-2' }}
          </td>
          <td
            [colSpan]="
              isExpanded
                ? (useUserCostType ? 1 : 0) +
                  (usePickOvertimeOnTimereport ? 1 : 0) +
                  (useNotarized ? 1 : 0) +
                  3
                : 2
            "
          ></td>
        </tr>
        <tr *ngIf="showFullSummary">
          <td
            [colSpan]="
              isExpanded
                ? (useUserCostType ? 1 : 0) +
                  (usePickOvertimeOnTimereport ? 1 : 0) +
                  (setMile ? 1 : 0) +
                  (setMile && setPrivMile ? 1 : 0) +
                  8
                : 6
            "
            style="border-top: 0"
          >
            <div class="p-col-6 p-px-0 p-d-flex p-flex-column">
              <span
                >Antal dagar:
                {{ fullCalculationSummary.totalDays | number: '1.2-2' }}
                st</span
              >
              <span class="p-mt-1"
                >Fakturerade timmar:
                {{ fullCalculationSummary.hoursInvoiced | number: '1.2-2' }}
                st</span
              >
              <span class="p-mt-1"
                >Ej fakturerade timmar:
                {{ fullCalculationSummary.hoursNotInvoiced | number: '1.2-2' }}
                st</span
              >
            </div>
            <div class="p-col-6 p-px-0 p-d-flex p-flex-column" *ngIf="setMile">
              <span class="p-mt-1"
                >Fakturerade mil:
                {{ fullCalculationSummary.milesInvoiced | number: '1.0-2' }}
                st</span
              >
              <span class="p-mt-1"
                >Ej fakturerade mil:
                {{ fullCalculationSummary.milesNotInvoiced | number: '1.0-2' }}
                st</span
              >
            </div>
            <div class="p-col-6 p-px-0 p-d-flex p-flex-column">
              <span class="p-mt-1">Traktamenten</span>
              <span class="p-mt-1"
                >Heldag:
                {{ fullCalculationSummary.subsistenceDays }}
                st</span
              >
              <span class="p-mt-1"
                >Halvdag:
                {{ fullCalculationSummary.subsistenceHalfDays }}
                st</span
              >
              <span class="p-mt-1"
                >Natt:
                {{ fullCalculationSummary.subsistenceNights }}
                st</span
              >
            </div>
          </td>
        </tr>
      </ng-template>
    </p-treeTable>
  </div>

  <div class="p-col-12">
    <button
      pButton
      style="float: right"
      class="p-button-primary-hyperion"
      label="Lägg till tidrapporter"
      icon="pi pi-plus"
      (click)="showCreateNewDayForm()"
    ></button>
  </div>

  <p-sidebar
    showCloseIcon="false"
    [(visible)]="isDayFormSidebarVisible"
    (onHide)="onHideDayFormSidebar()"
    position="right"
    [style]="{ width: '45rem' }"
  >
    <h1 *ngIf="(selectedDayEditId || selectedDayEdit) && !isEditHeader">
      Redigera tidrapport
    </h1>
    <h1 *ngIf="(selectedDayEditId || selectedDayEdit) && isEditHeader">
      Redigera fakturaunderlag
    </h1>
    <h1 *ngIf="!selectedDayEditId && !selectedDayEdit">Skapa tidrapport</h1>
    <app-day-form
      (dayUpdatedEvent)="onDayUpdatedEvent($event)"
      [day]="{ dayId: selectedDayEditId, day: selectedDayEdit }"
      [projectId]="projectId"
      [isExtra]="isExtra"
    ></app-day-form>
  </p-sidebar>
</div>
