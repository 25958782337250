import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, map, first, BehaviorSubject } from 'rxjs';
import {
  FetchProjectGQL,
  FetchProjectQuery,
  UpdateAllowReportingGQL,
  UpdateProjectDoneStateGQL,
  UpdateProjectPricesFromClientGQL,
  UpdateShortMessageGQL,
} from './graphql/project.generated';
import { MessageService } from '../../../shared/message/index';
import { AppDialogService } from 'app/shared/dialogs';

import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import { ProjectUnderlagComponent } from 'app/old-project/project-underlag/project-underlag.component';
import { InvoiceContainerComponent } from 'app/invoice/single/invoice-container.component';
import PROJECT_STATUS, {
  PROJECT_STATUS_LABELS,
} from 'app/shared/global/project-status.enum';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ProjectMoveService } from 'app/project/project-move.service';
import { Project } from 'generated/types';
import PROJECT_DONE_STATE, {
  projectDoneStateLabels,
} from 'app/shared/global/project-done-state.enum';
import { ConfirmDialog } from 'primeng/confirmdialog';
import { SplitIoService } from 'app/shared/split-io.service';
import { ProjectChangedService } from 'app/project/project-changed.service';

@Component({
  selector: 'app-project-info',
  templateUrl: './project-info.component.html',
  styleUrls: ['./project-info.component.scss'],
})
export class ProjectInfoComponent implements OnInit {
  public projectId: number;
  public userFlags: UserFlags;
  public allowReporting: boolean;
  public hasExtra = false;
  public showEconomy = false;
  public isLarge = true;
  public allowCreateInvoice = false;

  private readonly largeProjectLimit = 150;
  private forceLazyTimereports = false;
  public status: { value: PROJECT_STATUS; label: string } = {
    value: null,
    label: '',
  };

  public done: PROJECT_DONE_STATE;
  public doneLabel: string;
  public doneLabels = projectDoneStateLabels;

  readonly PROJECT_STATUS: typeof PROJECT_STATUS = PROJECT_STATUS;
  readonly PROJECT_DONE_STATE: typeof PROJECT_DONE_STATE = PROJECT_DONE_STATE;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private confirmationsService: ConfirmationService,
    private fetchProject: FetchProjectGQL,
    private updateShortMessage: UpdateShortMessageGQL,
    private messageService: MessageService,
    private userFlagsService: UserFlagsService,
    private dialogService: AppDialogService,
    private moveProjectService: ProjectMoveService,
    private updateAllowReportingService: UpdateAllowReportingGQL,
    private updateProjectDoneStateService: UpdateProjectDoneStateGQL,
    private updateProjectPricesFromClientMutation: UpdateProjectPricesFromClientGQL,
    private projectChangedService: ProjectChangedService
  ) {}

  public projectData: Observable<FetchProjectQuery['project']>;
  private projectDataSub = new BehaviorSubject<FetchProjectQuery['project']>({
    id: null,
  });

  public moveMenu: MenuItem[] = [
    {
      label: PROJECT_STATUS_LABELS[PROJECT_STATUS.ONGOING].label,
      icon: 'pi pi-play',
      command: _ => this.moveToStatus(PROJECT_STATUS.ONGOING),
    },
    {
      label: PROJECT_STATUS_LABELS[PROJECT_STATUS.PLANNED].label,
      icon: 'pi pi-calendar',
      command: _ => this.moveToStatus(PROJECT_STATUS.PLANNED),
    },
    {
      label: PROJECT_STATUS_LABELS[PROJECT_STATUS.FINISHED].label,
      icon: 'pi pi-history',
      command: _ => this.moveToStatus(PROJECT_STATUS.FINISHED),
    },
    {
      label: PROJECT_STATUS_LABELS[PROJECT_STATUS.ARCHIVED].label,
      icon: 'pi pi-inbox',
      command: _ => this.moveToStatus(PROJECT_STATUS.ARCHIVED),
    },
  ];

  public ngOnInit(): void {
    this.route.parent.params.subscribe(params => {
      this.projectId = params.id;
      this.userFlagsService
        .getFlags()
        .pipe(first())
        .subscribe(flags => {
          this.userFlags = flags;
          (this.showEconomy =
            this.userFlags.isAdmin ||
            (this.userFlags.isForeman &&
              this.userFlags.hasFlag('advancedUserCanUseInvoiceModule'))),
            this.setProjectData();

          if (
            this.userFlags.isAdmin ||
            (this.userFlags.isForeman &&
              this.userFlags.hasFlag('advancedUserCanUseInvoiceModule'))
          ) {
            this.allowCreateInvoice = true;
          }
        });
    });
    this.route.queryParams.subscribe(params => {
      if ('forceLazyTimereports' in params) {
        window.localStorage.setItem(
          'forceLazyTimereports',
          String(Number(params.forceLazyTimereports))
        );
      }
      this.forceLazyTimereports = Boolean(
        Number(window.localStorage.getItem('forceLazyTimereports'))
      );
    });
    this.projectData = this.projectDataSub.asObservable();
    this.projectDataSub.pipe(first()).subscribe(data => {
      this.allowReporting = Boolean(data.availableForTimeReport);
    });
  }

  public onInfoDataChanged(_: any): void {
    this.setProjectData();
    this.projectChangedService.projectChanged();
  }

  public onClientChanged(contactId: number): void {
    this.fetchProject
      .fetch({ id: this.projectId })
      .pipe(first())
      .subscribe(res => {
        const projectData = res.data.project;

        if (
          projectData.clientContact.hourCost === null &&
          projectData.clientContact.costProcent === null
        ) {
          this.setProjectData();
          return;
        }

        if (!this.isProjectPricesSameAsClient(projectData)) {
          this.confirmationsService.confirm({
            header: 'Uppdatera projektet med kundens priser?',
            message:
              'Du har angett specifika priser för arbetstid och/eller materialpåslag för denna kund. Vill du använda de priserna/påslagen i detta projekt?',
            acceptLabel: 'Ja',
            rejectLabel: 'Nej, behåll standardpriser',
            accept: () => {
              this.updateProjectPricesFromClient(
                Number(projectData.id),
                Number(contactId)
              );
            },
            reject: () => {
              this.setProjectData();
            },
          });
        } else {
          this.setProjectData();
        }
      });
  }

  private isProjectPricesSameAsClient(
    projectData: FetchProjectQuery['project']
  ): boolean {
    const projectHourWageCost = projectData.userCostTypes.edges[0].node.cost;
    const projectProcent = projectData.projectCostTypes.edges[0].node.procent;
    const clientCostProcent = projectData.clientContact.costProcent;
    const clientHourCost = projectData.clientContact.hourCost;

    const isHourCostSame =
      clientHourCost !== null ? projectHourWageCost === clientHourCost : true;
    const isProcentSame =
      clientCostProcent !== null ? projectProcent === clientCostProcent : true;

    return isHourCostSame && isProcentSame;
  }

  private updateProjectPricesFromClient(
    projectId: number,
    contactId: number
  ): void {
    this.updateProjectPricesFromClientMutation
      .mutate({
        contactId,
        projectId,
      })
      .subscribe(() => {
        this.setProjectData();
      });
  }

  public handleKeyUp(event, shortmessage: string): void {
    if (event.keyCode === 13) {
      this.saveMessage(shortmessage);
    }
  }

  public saveMessage(shortmessage: string): void {
    this.updateShortMessage
      .mutate({
        projectId: Number(this.projectId),
        shortMessage: shortmessage,
      })
      .pipe(first())
      .subscribe(data => {
        const errors =
          data.data.projectTypeHyperionMutation.mutationDetails[0].errors;
        if (!errors) {
          this.messageService.insertData({
            summary: 'Meddelande uppdaterat!',
            type: 'success',
          });
        } else {
          this.messageService.insertData({
            summary: 'Meddelandet kunde inte uppdateras',
            textArray: errors,
            type: 'error',
          });
        }
      });
  }

  public onProjectProductUpdatedEvent(updated: boolean): void {
    if (updated) {
      this.setProjectData();
    }
  }

  private setProjectData(): void {
    this.fetchProject
      .fetch({ id: this.projectId })
      .pipe(
        first(),
        map(result => result.data.project)
      )
      .subscribe(data => {
        this.projectDataSub.next(data),
          (this.allowReporting = Boolean(data.availableForTimeReport));
        this.hasExtra = this.getHasExtra(data);
        this.isLarge =
          data.totalTimeReports > this.largeProjectLimit ||
          this.forceLazyTimereports;
        this.status = PROJECT_STATUS_LABELS.find(l => l.value === data.status);
        this.done = data.done;
        this.doneLabel =
          projectDoneStateLabels.find(label => label.value === data.done)
            ?.label ||
          projectDoneStateLabels[PROJECT_DONE_STATE.NOT_DONE].label;
      });
  }

  public getHasExtra(projectData: FetchProjectQuery['project']): boolean {
    const hasExtraDays = projectData.totalTimeReportsExtra > 0;
    const hasExtraProducts = projectData.projectCostTypes.edges.some(e =>
      e.node.products.edges.some(p => p.node.extra === '1')
    );

    return hasExtraDays || hasExtraProducts;
  }

  public refresh(): void {
    this.setProjectData();
  }

  public openReport(): void {
    this.projectData.pipe(first()).subscribe(projectData => {
      this.dialogService.layout = 'wide';
      this.dialogService.data = {
        projectInfo: projectData,
        functionsThisModel: {},
      };
      this.dialogService.openComponent(ProjectUnderlagComponent);
    });
  }

  public openInvoiceDialog(): void {
    this.dialogService.layout = 'wide';
    this.dialogService.data = {
      projectIdFromDialpgRef: this.projectId,
    };
    this.dialogService
      .openComponent(InvoiceContainerComponent, null, 'dark-background')
      .onClose.subscribe(() => {
        this.setProjectData();
      });
  }

  private moveToStatus(status: PROJECT_STATUS): void {
    this.projectData.pipe(first()).subscribe(project => {
      this.moveProjectService
        .moveProject(project as Project, status)
        .pipe(first())
        .subscribe(moved => {
          if (moved) {
            this.refresh();
          }
        });
    });
  }

  public deleteProject(): void {
    this.projectData.pipe(first()).subscribe(project => {
      this.moveProjectService
        .moveProject(project as Project, PROJECT_STATUS.DELETED)
        .pipe(first())
        .subscribe(() => {
          this.router.navigate(['project']);
        });
    });
  }
  public exportExcel(): void {
    const url = '/project/export/' + this.projectId;

    window.open(url);
  }

  public toggleAllowReporting(): void {
    const newVal = Number(!this.allowReporting);
    this.confirmationsService.confirm({
      header: newVal
        ? 'Slå på tidsrapportering?'
        : 'Stäng av tidsrapportering?',
      message: newVal
        ? 'Vill du slå på tidsrapportering för projektet?'
        : 'Vill du stänga av tidsrapportering för projektet?',
      accept: () =>
        this.updateAllowReportingService
          .mutate({ projectId: this.projectId, allowReporting: newVal })
          .pipe(first())
          .subscribe(res => {
            this.messageService.insertDataFromMutation(
              res.data.projectTypeHyperionMutation
            );
            this.refresh();
          }),
    });
  }

  public disableAllowReporting(): void {
    this.updateAllowReportingService
      .mutate({ projectId: this.projectId, allowReporting: 0 })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectTypeHyperionMutation
        );
        this.refresh();
      });
  }

  public updateDoneState(state: PROJECT_DONE_STATE, cd?: ConfirmDialog): void {
    this.updateProjectDoneStateService
      .mutate({
        projectId: this.projectId,
        done: state,
      })
      .pipe(first())
      .subscribe(res => {
        this.messageService.insertDataFromMutation(
          res.data.projectTypeHyperionMutation
        );
        if (cd) {
          cd.hide();
        }

        this.refresh();
      });
  }

  public toggleMarkAsDone(): void {
    switch (this.done) {
      case PROJECT_DONE_STATE.DONE_WITH_CHANGES:
        this.confirmationsService.confirm({
          key: 'confirmMarkAsDoneWithChanges',
        });
        break;
      case PROJECT_DONE_STATE.DONE:
        this.confirmationsService.confirm({
          key: 'confirmMarkAsNotDone',
          accept: () => {
            this.updateDoneState(PROJECT_DONE_STATE.NOT_DONE);
          },
        });
        break;
      case PROJECT_DONE_STATE.NOT_DONE:
      default:
        this.confirmationsService.confirm({
          key: 'confirmMarkAsDone',
          accept: () => {
            this.updateDoneState(PROJECT_DONE_STATE.DONE);

            if (this.allowReporting) {
              this.toggleAllowReporting();
            }
          },
        });
        break;
    }
  }
}
