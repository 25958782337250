<div class="p-grid smaller-inputs" style="border-top: 1px #e6e5e5 solid">
  <div class="p-col-12" style="display: flex">
    <div style="margin-right: 7px">
      <p-checkbox
        value="2"
        name="groupname"
        label="Omvänd skattskyldighet"
        [(ngModel)]="selectedOfferType"
        (onChange)="doSingleSelect()"
      ></p-checkbox>
    </div>
    <div style="margin-right: 7px">
      <p-checkbox
        value="3"
        name="groupname"
        label="ROT"
        [(ngModel)]="selectedOfferType"
        (onChange)="doSingleSelect()"
      ></p-checkbox>
    </div>

    <div style="margin-right: 7px">
      <p-checkbox
        value="5"
        name="groupname"
        label="Grön skattereduktion"
        [(ngModel)]="selectedOfferType"
        (onChange)="doSingleSelect()"
      ></p-checkbox>
    </div>

    <div>
      <p-checkbox
        value="1"
        label="Visa endast totaler"
        [binary]="true"
        [(ngModel)]="offerInfo['groupCosts']"
        (onChange)="doSingleSelect()"
      ></p-checkbox>
    </div>
  </div>

  <div class="p-col-12 p-md-8 p-g-nopad">
    <div class="p-col-12 p-g-nopad">
      <div class="p-grid" *ngIf="typeOffer == '3' || typeOffer == '5'">
        <div class="p-col-12 p-md-6">
          <label class="form-control-label"
            >ROT/Grön person 1 <small>inkl.moms</small></label
          >
        </div>
        <div class="p-col-12 p-md-6">
          <input
            style="width: 100%"
            autocomplete="off"
            (input)="newRotDataEmit()"
            type="text"
            class="form-control"
            [class.has-danger]="+dataSet.rotPerson1 > maxRotPerPerson"
            placeholder="kr"
            pInputText
            [(ngModel)]="dataSet.rotPerson1"
          />
        </div>
        <div class="p-col-12" *ngIf="+dataSet.rotPerson1 > maxRotPerPerson">
          <p class="form-control-feedback">
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="errors.rotPerson"></span>
          </p>
        </div>
        <div class="p-col-12 p-md-6 flex-vertical-align">
          <label class="form-control-label"
            >ROT/Grön person 2 <small>inkl.moms</small></label
          >
        </div>
        <div class="p-col-12 p-md-6">
          <input
            style="width: 100%"
            autocomplete="off"
            (input)="newRotDataEmit()"
            type="text"
            class="form-control"
            [class.has-danger]="+dataSet.rotPerson2 > maxRotPerPerson"
            placeholder="kr"
            pInputText
            [(ngModel)]="dataSet.rotPerson2"
          />
        </div>
        <div class="p-col-12" *ngIf="+dataSet.rotPerson2 > maxRotPerPerson">
          <p class="form-control-feedback">
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="errors.rotPerson"></span>
          </p>
        </div>
        <div class="p-col-6">
          <label class="form-control-label"
            >Max ROT/Grön utifrån angivet ovan:</label
          >
        </div>
        <div class="p-col-6">
          <label class="form-control-label"
            ><strong>{{ totalPersonRot | currency: 'SEK' }}</strong></label
          >
        </div>
      </div>
    </div>

    <div class="p-col-12">
      <div class="p-grid">
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">Summa arbete</label>
        </div>
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">{{
            summations.work | async | currency: 'SEK'
          }}</label>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">Summa material</label>
        </div>
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">{{
            summations.material | async | currency: 'SEK'
          }}</label>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">Summa exkl. moms</label>
        </div>
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">{{
            summations.totalExVat | async | currency: 'SEK'
          }}</label>
        </div>
      </div>
      <div class="p-grid" *ngIf="typeOffer != '2'">
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">Summa inkl. moms</label>
        </div>
        <div class="p-col-6 p-g-nopad">
          <label class="form-control-label">{{
            summations.totalInclVat | async | currency: 'SEK'
          }}</label>
        </div>
      </div>
    </div>

    <div class="p-col-12 p-g-nopad">
      <div class="p-grid" *ngIf="typeOffer == '3'">
        <div class="p-col-6">
          <label class="form-control-label">50% ROT</label>
        </div>
        <div class="p-col-6">
          <label class="form-control-label">
            <p-checkbox
              [binary]="true"
              name="groupname"
              label="Ja"
              [(ngModel)]="rot50"
              (onChange)="updateRotPct()"
            ></p-checkbox>
          </label>
        </div>
        <div class="p-col-12" *ngIf="rot50">
          50% ROT-avdrag gäller endast för de projekt som kunden betalar efter
          2025-05-12. Betalar kunden tidigare än så gäller 30% ROT-avdrag.
        </div>
      </div>

      <div class="p-grid" *ngIf="typeOffer == '3'">
        <div class="p-col-6">
          <label class="form-control-label">Max ROT efter arbetsmoment</label>
        </div>
        <div class="p-col-6">
          <label class="form-control-label">{{
            summations.maxRot | async | currency: 'SEK'
          }}</label>
        </div>
      </div>
      <div class="p-grid" *ngIf="typeOffer == '5'">
        <div class="p-col-6">
          <label class="form-control-label"
            >Max Grön arbetsmoment/material</label
          >
        </div>
        <div class="p-col-6">
          <label class="form-control-label">{{
            summations.maxRot | async | currency: 'SEK'
          }}</label>
        </div>
      </div>
      <div class="p-grid" *ngIf="typeOffer == '3' || typeOffer == '5'">
        <div class="p-col-12 p-md-6 flex-vertical-align">
          <label class="form-control-label"
            >ROT/Grön-avdrag <small>ink. moms</small></label
          >
        </div>
        <div class="p-col-12 p-md-6">
          <input
            style="width: 100%"
            autocomplete="off"
            type="text"
            (input)="newRotDataEmit()"
            class="form-control"
            placeholder="kr"
            pInputText
            [(ngModel)]="dataSet.rotSum"
          />
          <!--[class.has-danger]="+dataSet.rotPerson2 > 50000"-->
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-6">
          <label class="form-control-label">Att betala</label>
        </div>
        <div class="p-col-6">
          <label class="form-control-label"
            ><span *ngIf="!(errors.rotSum.show | async)">{{
              summations.sumToPay | async | currency: 'SEK'
            }}</span
            ><span *ngIf="errors.rotSum.show | async">Fel!</span></label
          >
        </div>
        <div class="p-col-12" *ngIf="errors.rotSum.show | async">
          <p class="form-control-feedback" style="position: relative">
            <span><i class="fa fa-times"></i> </span
            ><span [innerHTML]="errors.rotSum.text"></span>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
