import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { UpdateDayMutationGQL } from 'app/day/day-form/graphql/day.mutation.generated';
import {
  FetchAttendanceTypesGQL,
  FetchAttendanceTypesQuery,
  FetchCompanyUsersCostTypesGQL,
  FetchCompanyUsersCostTypesQuery,
} from 'app/day/day-form/graphql/day.query.generated';
import {
  GetTodosGQL,
  TodoFragment,
} from 'app/project/project-detail/project-todo/graphql/project-todo.generated';
import { CompanyFunctionsService } from 'app/shared/company';
import { UserLocalStorageService } from 'app/shared/user';
import { UserFlags, UserFlagsService } from 'app/user-flags.service';
import { CompanyUserCostType, Day } from 'generated/types';
import cloneDeep from 'lodash.clonedeep';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { Table } from 'primeng/table';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  first,
  map,
  Observable,
  Subscription,
} from 'rxjs';
import { FetchProjectQuery } from '../../graphql/project.generated';
import {
  DeleteDayGQL,
  GetProjectDaysGQL,
  ProjectDayFragment,
  SetNotarizedGQL,
  SetDayExtraGQL,
  UpdateTextGQL,
  RemoveTimeReportInvoiceConnectionGQL,
} from './graphql/day.generated';

type FullSummaryCalculations = {
  totalDays: number;
  hoursInvoiced: number;
  hoursNotInvoiced: number;
  milesInvoiced: number;
  milesNotInvoiced: number;
  subsistenceDays: number;
  subsistenceHalfDays: number;
  subsistenceNights: number;
};

type FieldsSummary = {
  hours: number;
  hoursToInvoice: number;
  privMile: number;
  mileToInvoice: number;
  mile: number;
};

type Column = {
  header: string;
  field: string;
  width?: string;
  icon?: string;
  tooltip?: string;
  editable?: boolean;
};

type DaySummary = {
  id: number;
  day: ProjectDayFragment;
  userCostType: CompanyUserCostType;
  attendanceType: {
    id?: number;
    code?: string;
    name?: string;
  };
  isFullDayEmpty: boolean;
  isFullDayInvoiced: boolean;
  parentId: number;
  isSaving?: boolean;
  editDisabled: boolean;
  hasProducts: boolean;
  hasChildren: boolean;
};

type UserCostType = {
  id: string;
  name?: string;
  active?: number;
};

type AttendanceType = {
  id?: number;
  code?: string;
  name?: string;
};

@Component({
  selector: 'app-project-work-performed',
  templateUrl: './project-work-performed.component.html',
  styleUrls: ['./project-work-performed.component.scss'],
})
export class ProjectWorkPerformedComponent implements OnChanges, OnDestroy {
  @Input() public projectData: Observable<FetchProjectQuery['project']>;
  @Input() public isExpanded = false;
  @Input() public isExtra = false;
  @Output() public dayUpdatedEvent = new EventEmitter<number>();

  @ViewChild('table')
  private table: Table;

  private projectDataSubscription: Subscription;

  public days: DaySummary[] = [];
  private companyUsersCostTypesData = new BehaviorSubject<
    FetchCompanyUsersCostTypesQuery['company']['userCostTypes']
  >({});
  private userCostTypes: UserCostType[];
  private attendanceTypesData = new BehaviorSubject<
    FetchAttendanceTypesQuery['company']['dayAttendanceTypes']
  >({});
  private attendanceTypes: AttendanceType[];

  public isDayFormSidebarVisible = false;
  public areTimereportsExpanded = false;
  public projectId: number;
  public selectedDayEditId: number | null;
  public selectedDayEdit: Day | null;
  public showFullSummary = false;
  public actionMenus: MenuItem[];
  public fullCalculationSummary: FullSummaryCalculations;
  public fieldsSummary: FieldsSummary = {
    hours: 0,
    hoursToInvoice: 0,
    privMile: 0,
    mileToInvoice: 0,
    mile: 0,
  };

  public columns: Column[] = [
    {
      header: 'Datum',
      field: 'date',
      width: '9rem',
    },
    {
      header: 'Utfört arbete',
      field: 'doneWork',
      editable: true,
    },
    {
      header: 'F. timmar',
      field: 'hoursToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: 'F. mil',
      field: 'mileToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];

  public extendedColumns: Column[] = [
    {
      header: 'Datum',
      field: 'date',
      width: '9rem',
    },
    {
      header: 'Utfört arbete',
      field: 'doneWork',
      editable: true,
    },
    {
      header: 'Timmar',
      field: 'hours',
      width: '6rem',
      editable: true,
    },
    {
      header: 'F. timmar',
      field: 'hoursToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: 'Priv. mil',
      field: 'privMile',
      width: '6rem',
      editable: true,
    },
    {
      header: 'F. mil',
      field: 'mileToInvoice',
      width: '6rem',
      editable: true,
    },
    {
      header: 'Närvarotyp',
      field: 'overtimeHardCoded',
      width: '10rem',
      editable: true,
    },
    {
      header: 'Yrkestyp',
      field: 'companyCostTypeId',
      width: '10rem',
      editable: true,
    },
    {
      header: '',
      icon: 'pi pi-check',
      field: 'notarized',
      tooltip: 'Attesterad',
      width: '3.5rem',
    },
    {
      header: '',
      field: 'invoiceId',
      width: '3rem',
      icon: 'pi pi-wallet',
      tooltip: 'Fakturerad',
    },
    {
      header: '',
      field: 'todoId',
      width: '3rem',
      icon: 'pi pi-clock',
      tooltip: 'Arbetsmoment',
    },
    {
      header: '',
      field: 'actionMenu',
      width: '4rem',
    },
  ];
  private userFlags: UserFlags;
  public usePickOvertimeOnTimereport: boolean;
  public useUserCostType: boolean;
  public setMile: boolean;
  public setPrivMile: boolean;
  public useNotarized: boolean;
  public afterNotarizedNoUpdateAbility: boolean;

  public editing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public userCosttypeDropdown: { label: string; value: number | null }[];
  public attendanceTypeDropdown: { label: string; value: number | null }[];
  public isEditHeader: boolean;
  public todoMap: { [key: string]: TodoFragment } = {};
  public daySummaries: { [key: string]: { [key: string]: number } } = {};

  constructor(
    private deleteDayGQL: DeleteDayGQL,
    private setNotarizedGQL: SetNotarizedGQL,
    private fetchCompanyUsersCostTypesGQL: FetchCompanyUsersCostTypesGQL,
    private fetchAttendanceTypesGQL: FetchAttendanceTypesGQL,
    private userFlagsService: UserFlagsService,
    private userLocalStorageService: UserLocalStorageService,
    private companyFunctionService: CompanyFunctionsService,
    private updateTextService: UpdateTextGQL,
    private updateDayGQL: UpdateDayMutationGQL,
    private getProjectDaysService: GetProjectDaysGQL,
    private confirmationService: ConfirmationService,
    private setDayExtraGQL: SetDayExtraGQL,
    private getTodosGQL: GetTodosGQL,
    private removeTimeReportInvoiceConnectionService: RemoveTimeReportInvoiceConnectionGQL
  ) {
    this.fetchAttendanceTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(result => {
        this.attendanceTypesData.next(result.data.company.dayAttendanceTypes);

        this.attendanceTypeDropdown =
          result.data.company.dayAttendanceTypes.edges
            .map(e => e.node)
            .map(ct => ({ label: ct.name, value: Number(ct.id) }));
      });

    this.fetchCompanyUsersCostTypesGQL
      .fetch()
      .pipe(first())
      .subscribe(result => {
        this.companyUsersCostTypesData.next(result.data.company.userCostTypes);

        this.userCosttypeDropdown = result.data.company.userCostTypes.edges
          .map(e => e.node)
          .filter(ct => ct.active === 1)
          .map(ct => ({ label: ct.name, value: Number(ct.id) }));
        this.userCosttypeDropdown.unshift({
          label: 'Inget valt...',
          value: null,
        });
      });

    this.userFlagsService
      .getFlags()
      .pipe(first())
      .subscribe(flags => {
        this.userFlags = flags;
        this.usePickOvertimeOnTimereport = flags.hasFlag(
          'usePickOvertimeOnTimereport'
        );
        this.useUserCostType = flags.hasFlag('useUserCostType');
        this.setMile = flags.hasFlag('setMile');
        this.setPrivMile = flags.hasFlag('setPrivMile');
        this.useNotarized = flags.hasFlag('useNotarized');
        this.afterNotarizedNoUpdateAbility = flags.hasFlag(
          'afterNotarizedNoUpdateAbility'
        );
      });
  }
  public ngOnChanges(changes: SimpleChanges): void {
    if (!('projectData' in changes)) {
      return;
    }

    this.projectDataSubscription?.unsubscribe();
    this.projectDataSubscription = this.projectData.subscribe(projectData => {
      this.projectId = Number(projectData.id);
      this.updateDayData();
      this.setSummaryData(projectData);
      this.getTodosGQL
        .fetch({ projectId: this.projectId })
        .pipe(
          first(),
          map(res => res.data.project.todos.edges.map(e => e.node))
        )
        .subscribe(todos => {
          todos.forEach(todo => (this.todoMap[todo.id] = todo));
        });
    });
  }

  public ngOnDestroy(): void {
    this.projectDataSubscription?.unsubscribe();
  }

  private updateDayData(): void {
    combineLatest({
      companyUsersCostTypesData: this.companyUsersCostTypesData,
      attendanceTypesData: this.attendanceTypesData,
    }).subscribe(({ companyUsersCostTypesData, attendanceTypesData }) => {
      this.userCostTypes = companyUsersCostTypesData.edges?.map(
        ({ node }) => node
      );

      this.attendanceTypes = attendanceTypesData.edges?.map(({ node }) => {
        return {
          id: +node.id,
          code: node.code,
          name: node.name,
        };
      });

      this.fetchDays();
    });
  }

  private fetchDays(): void {
    this.getProjectDaysService
      .fetch({ projectId: this.projectId })
      .pipe(first())
      .subscribe(dayData => {
        const days = dayData.data.project.days.edges
          .map(e => e.node)
          .filter(d => Boolean(d.extra) === this.isExtra);

        const daySummaries = {};

        this.days = days
          .sort((a, b) => {
            const dateDiff =
              new Date(a.date).valueOf() - new Date(b.date).valueOf();
            return dateDiff !== 0 ? dateDiff : a.userId - b.userId;
          })
          .map(day => {
            if (!daySummaries[day.date]) {
              daySummaries[day.date] = {
                hoursToInvoice: 0,
                hours: 0,
                mileToInvoice: 0,
                mile: 0,
                privMile: 0,
              };
            }
            const userCostType = this.userCostTypes?.find(
              uct =>
                Number(uct.id) ===
                Number(day.costTypeHyperion?.companyCostTypeId)
            );
            // const attendanceType = this.attendanceTypes?.find(
            //   at => at.code === day.overtimeHardCoded
            // );

            const isFullDayEmpty =
              !day.userId && days.filter(d => d.date === day.date).length === 1;

            const dateDays = days.filter(d => d.date === day.date && d.userId);

            const isFullDayInvoiced =
              dateDays.length > 0 && dateDays.every(d => d.invoiceId);

            const notarizedUserInitials = day.notarized
              ? this.userLocalStorageService.getMEUser().initials
              : '';

            const parentId = Number(days.find(d => day.date === d.date).id);

            Object.keys(daySummaries[day.date]).forEach(key => {
              daySummaries[day.date][key] += Number(day[key]);
            });

            day = cloneDeep(day);
            day.newCostTypeId = day.costTypeHyperion?.companyCostTypeId;

            return {
              id: Number(day.id),
              parentId: parentId,
              day: day,
              userCostType: userCostType,
              attendanceType: day.attendanceType
                ? {
                    id: +day.attendanceType.id,
                    code: day.attendanceType.code,
                    name: day.attendanceType.name,
                  }
                : null,
              isFullDayEmpty: isFullDayEmpty,
              isFullDayInvoiced: isFullDayInvoiced,
              notarizedUserInitials: notarizedUserInitials,
              editDisabled: this.editDisabled(day),
              hasProducts: day.stats?.productCount > 0,
              hasChildren: day.stats?.childCount > 0,
            };
          });

        this.daySummaries = daySummaries;
      });
  }

  private setSummaryData(projectData: FetchProjectQuery['project']): void {
    this.updateDayData();
    this.fullCalculationSummary = this.isExtra
      ? {
          totalDays: projectData.totalDaysExtra,
          hoursInvoiced: projectData.hoursInvoicedExtra,
          hoursNotInvoiced: projectData.hoursNotInvoicedExtra,
          milesInvoiced: projectData.milesInvoicedExtra,
          milesNotInvoiced: projectData.milesNotInvoicedExtra,
          subsistenceDays: projectData.subsistenceDaysExtra,
          subsistenceHalfDays: projectData.subsistenceHalfDaysExtra,
          subsistenceNights: projectData.subsistenceNightsExtra,
        }
      : {
          totalDays: projectData.totalDays,
          hoursInvoiced: projectData.hoursInvoiced,
          hoursNotInvoiced: projectData.hoursNotInvoiced,
          milesInvoiced: projectData.milesInvoiced,
          milesNotInvoiced: projectData.milesNotInvoiced,
          subsistenceDays: projectData.subsistenceDays,
          subsistenceHalfDays: projectData.subsistenceHalfDays,
          subsistenceNights: projectData.subsistenceNights,
        };
    this.fieldsSummary = this.isExtra
      ? {
          hours: projectData.totalHoursExtra,
          hoursToInvoice:
            projectData.hoursInvoicedExtra + projectData.hoursNotInvoicedExtra,
          mile:
            projectData.milesInvoicedExtra + projectData.milesNotInvoicedExtra,
          privMile: projectData.milesPrivateExtra,
          mileToInvoice: projectData.milesNotInvoicedExtra,
        }
      : {
          hours: projectData.totalHours,
          hoursToInvoice:
            projectData.hoursInvoiced + projectData.hoursNotInvoiced,
          mile: projectData.milesInvoiced + projectData.milesNotInvoiced,
          privMile: projectData.milesPrivate,
          mileToInvoice:
            projectData.milesInvoiced + projectData.milesNotInvoiced,
        };
  }

  public recheckOpenedNodes(): void {
    this.areTimereportsExpanded =
      Object.keys(this.table.expandedRowKeys).length > 0;
  }

  public toggleAll(event: Event) {
    const days = this.table.value;
    const headers = days.filter(x => x.id === x.parentId);
    if (this.areTimereportsExpanded) {
      for (const header of headers) {
        delete this.table.expandedRowKeys[header.day.date];
      }
    } else {
      for (const header of headers) {
        this.table.expandedRowKeys[header.day.date] = true;
      }
    }
    this.days = [...this.table.value];
    this.recheckOpenedNodes();
    event.preventDefault();
  }

  public calculateSumField(days: Day[], field: string): number {
    return days.map(day => day[field]).reduce((a, b) => a + b, 0);
  }

  public updateDaySums(date: string): void {
    Object.keys(this.daySummaries[date]).forEach(key => {
      this.daySummaries[date][key] = this.calculateDateSumField(
        this.days,
        key,
        date
      );
    });
  }

  private calculateDateSumField(
    days: DaySummary[],
    field: string,
    date: string
  ): number {
    return days
      .filter(day => day.day.date === date)
      .map(day => day.day[field])
      .reduce((a, b) => Number(a) + Number(b), 0);
  }

  public showDayFormSidebar(isEditHeader = false): void {
    this.isDayFormSidebarVisible = !this.isDayFormSidebarVisible;
    this.isEditHeader = isEditHeader;
  }

  public onDayUpdatedEvent(dayId: number): void {
    this.isDayFormSidebarVisible = false;
    this.selectedDayEditId = null;
    this.selectedDayEdit = null;
    this.isEditHeader = false;
    this.fetchDays();
    this.dayUpdatedEvent.emit(dayId);
  }

  public onHideDayFormSidebar(): void {
    this.selectedDayEditId = null;
    this.selectedDayEdit = null;
  }

  public toggleShowFullSummary(): void {
    this.showFullSummary = !this.showFullSummary;
  }

  public generateActionsMenu(dayData: DaySummary): void {
    this.actionMenus = [
      {
        label: `Redigera`,
        icon: 'pi pi-pencil',
        disabled: dayData.editDisabled,
        command: () => {
          this.showDayFormSidebar();
        },
      },
      {
        label: 'Ta bort',
        icon: 'pi pi-trash',
        disabled: dayData.editDisabled,
        command: () => {
          let message = 'Är du säker på att du vill ta bort tidrapporten?';
          if (dayData.hasProducts || dayData.hasChildren) {
            message +=
              ' Samtliga kopplade produkter och tidrapporter kommer raderas.';
          }
          this.confirmationService.confirm({
            message: message,
            accept: () =>
              this.deleteDayGQL
                .mutate({ deleteDay: { id: Number(dayData.id) } })
                .pipe(first())
                .subscribe(() => {
                  this.dayUpdatedEvent.emit(Number(dayData.id));
                }),
          });
        },
      },
      {
        label:
          dayData.day.extra === 1 ? 'Flytta till normal' : 'Flytta till extra',
        icon: 'pi pi-plus',
        command: () => {
          let message =
            dayData.day.extra === 1
              ? 'Är du säker på att du vill flytta tidrapporten till normal?'
              : 'Är du säker på att du vill flytta tidrapporten till extra?';
          if (
            dayData.hasProducts ||
            dayData.hasChildren ||
            dayData.day.parentId
          ) {
            message +=
              ' Samtliga kopplade produkter och tidrapporter kommer flyttas.';
          }
          this.confirmationService.confirm({
            message: message,
            accept: () =>
              this.setDayExtraGQL
                .mutate({ id: dayData.id, extra: dayData.day.extra === 1 })
                .pipe(first())
                .subscribe(() => {
                  this.dayUpdatedEvent.emit(Number(dayData.id));
                }),
          });
        },
      },
    ];
    if (this.canNotarize()) {
      this.actionMenus.push(
        dayData.day.notarized
          ? {
              label: 'Ta bort attestering',
              icon: 'pi pi-times',
              command: () => this.setNotarized(dayData, false),
            }
          : {
              label: 'Attestera',
              icon: 'pi pi-check',
              command: () => this.setNotarized(dayData, true),
            }
      );
    }
    if (this.userFlags.isSuperAdmin && dayData.isFullDayInvoiced) {
      this.actionMenus.push({
        label: 'Ta bort fakturakoppling',
        icon: 'pi pi-times',
        command: () => this.removeInvoiceConnection(dayData),
      });
    }
  }

  private removeInvoiceConnection(day: DaySummary) {
    this.removeTimeReportInvoiceConnectionService
      .mutate({
        timeReportId: day.id,
      })
      .pipe(first())
      .subscribe(result => {
        if (
          result.data.removeTimeReportInvoiceConnectionMutation
            .mutationDetails[0].mutationSucceeded
        ) {
          this.dayUpdatedEvent.emit(
            Number(result.data.removeTimeReportInvoiceConnectionMutation.id)
          );
        }
      });
  }

  private canNotarize(): boolean {
    const isAdmin = this.userFlags.isAdmin;
    const isForeman = this.userFlags.isForeman;

    const foremanCanNotarize = this.companyFunctionService.companyFunctionIsSet(
      'advancedUserCanNotarizeTimereports'
    );
    const isforemanNotarizer = foremanCanNotarize && isForeman;

    const userCanNotarize = isAdmin || isforemanNotarizer;
    const companyCanNotarize = this.userFlags.hasFlag('useNotarized');

    return userCanNotarize && companyCanNotarize;
  }

  public generateActionsMenuFullDay(dayData: DaySummary): void {
    this.actionMenus = [
      {
        label: 'Redigera',
        icon: 'pi pi-pencil',
        command: () => {
          this.showDayFormSidebar(true);
        },
      },
      {
        label: 'Ta bort',
        icon: 'pi pi-trash',
        disabled: !dayData.isFullDayEmpty,
        command: () => {
          this.deleteDayGQL
            .mutate({ deleteDay: { id: Number(dayData.id) } })
            .pipe(first())
            .subscribe(() => {
              this.dayUpdatedEvent.emit(Number(dayData.id));
            });
        },
      },
    ];
  }

  private setNotarized(day: DaySummary, notarized: boolean): void {
    this.setNotarizedGQL
      .mutate({
        day: {
          id: day.id,
          notarized: notarized
            ? Number(this.userLocalStorageService.getMEUser().id)
            : 0,
        },
      })
      .pipe(first())
      .subscribe(() => this.dayUpdatedEvent.emit(day.id));
  }

  /**
   * Checks if all day rows are invoiced a specific date
   */
  private isFullDayInvoiced(day: ProjectDayFragment, days: Day[]): boolean {
    const timeReports = days.filter(d => d.date === day.date && d.userId);

    if (!timeReports.length) {
      return false;
    }

    return timeReports.every(d => d.invoiceId);
  }

  public showCreateNewDayForm(): void {
    this.selectedDayEdit = null;
    this.selectedDayEditId = null;

    this.showDayFormSidebar();
  }

  public getColumns(): Column[] {
    const columns = this.isExpanded ? this.extendedColumns : this.columns;
    const disabledColumns = [];

    if (!this.usePickOvertimeOnTimereport) {
      disabledColumns.push('overtimeHardCoded');
    }
    if (!this.useUserCostType) {
      disabledColumns.push('companyCostTypeId');
    }
    if (!this.setMile) {
      disabledColumns.push('mileToInvoice');
    }
    if (!this.setMile || !this.setPrivMile) {
      disabledColumns.push('privMile');
    }
    if (!this.useNotarized) {
      disabledColumns.push('notarized');
    }
    return columns.filter(col => !disabledColumns.includes(col.field));
  }

  public getCompanyCostTypeName(costTypeId: number): BehaviorSubject<string> {
    const companyCostTypeNameSubject = new BehaviorSubject<string>('');

    this.companyUsersCostTypesData
      .pipe(
        first(),
        map(r => r.edges.map(e => e.node)),
        map(cs => cs.filter(c => c.active && Number(c.id) === costTypeId))
      )
      .subscribe(result => {
        const companyCostType = result[0];

        if (companyCostType) {
          companyCostTypeNameSubject.next(companyCostType.name);
        }
      });

    return companyCostTypeNameSubject;
  }

  public addTextToParent(text: string, parentId: number): void {
    const oldText = this.days.find(day => Number(day.id) === parentId).day
      .doneWork;
    const newText = oldText + '. ' + text;
    this.updateTextService
      .mutate({ id: parentId, text: oldText ? newText : text })
      .pipe(first())
      .subscribe(() => {
        this.dayUpdatedEvent.emit(Number(parentId));
      });
  }

  public setEditing(isEditing: boolean): void {
    this.editing.next(isEditing);
  }

  public updateDay(daySummary: DaySummary): void {
    const day = daySummary.day;
    daySummary.isSaving = true;
    this.updateDayGQL
      .mutate({
        updateDay: {
          id: Number(day.id),
          doneWork: day.doneWork,
          hours: String(day.hours),
          hoursToInvoice: String(day.hoursToInvoice),
          mile: String(day.mile),
          privMile: String(day.privMile),
          mileToInvoice: String(day.mileToInvoice),
          newCostTypeId: day.newCostTypeId,
          attendanceTypeId: Number(day.attendanceTypeId),
        },
      })
      .pipe(first())
      .subscribe(() => {
        daySummary.isSaving = false;
        this.editing.pipe(debounceTime(100)).subscribe(editing => {
          if (!editing) {
            this.dayUpdatedEvent.emit(Number(day.id));
            this.fetchDays();
          }
        });
      });
    this.updateDaySums(day.date);
  }

  public editDisabled(day: ProjectDayFragment): boolean {
    return (
      day.invoiceId !== null ||
      (this.afterNotarizedNoUpdateAbility && day.notarized !== 0)
    );
  }
}
