<div class="p-grid">
  <div class="p-col-12">
    <p-table
      class="inline-table no-alternating"
      [columns]="columns"
      [value]="(tableData | async).costs"
      [dataKey]="'id'"
      [(expandedRowKeys)]="expandedRows"
      [class.scrollable]="!isExpanded"
      [class.scrollable-fullscreen]="isExpanded"
      (onEditComplete)="setEditing(false)"
      (onEditInit)="setEditing(true)"
      (sortFunction)="sortCostTypes($event)"
      customSort="true"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th
            *ngFor="let col of columns"
            [style.width]="col.width"
            [pTooltip]="col.tooltip"
            [pSortableColumn]="col.field"
          >
            {{ col.header }}
            <i *ngIf="col.icon" [class]="col.icon"></i>
            <p-sortIcon *ngIf="col.sortable" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-rowData let-expanded="expanded">
        <tr [pRowToggler]="rowData">
          <td class="p-text-truncate" *ngFor="let col of columns">
            <span
              *ngIf="col.field === 'benamning'"
              class="p-text-bold p-d-flex p-jc-between"
            >
              {{ rowData[col.field] }}
              <i
                class="pi pi-chevron-right"
                [style.transform]="expanded ? 'rotate(90deg)' : 'rotate(0deg)'"
              ></i>
            </span>
            <span
              class="p-text-bold p-text-right"
              *ngIf="col.field === 'totalSum'"
            >
              {{ rowData[col.field] | currency: 'SEK' }}
            </span>
            <span
              class="p-text-bold p-text-right"
              *ngIf="col.field === 'totalSalesSum'"
            >
              {{ rowData[col.field] | currency: 'SEK' }}
            </span>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion" let-data let-i="rowIndex">
        <tr *ngFor="let product of data.products; index as j">
          <ng-container *ngFor="let col of columns">
            <td
              *ngIf="col.editable"
              [pEditableColumn]="product[col.field]"
              [pEditableColumnField]="col.field"
              [pEditableColumnDisabled]="product.invoiceId !== null"
            >
              <span
                class="p-d-block"
                *ngIf="col.field === 'artnr' || col.field === 'benamning'"
                [pTooltip]="
                  product.dayId || isExpanded
                    ? null
                    : 'Tillagd av ' +
                      product.user.firstName +
                      ' ' +
                      product.user.lastName +
                      ' ' +
                      product.created
                "
                style="white-space: pre-line"
              >
                <p-cellEditor (change)="updateProduct(product)">
                  <ng-template pTemplate="output">
                    <span class="p-d-flex p-jc-between">
                      {{ product[col.field] }}
                      <i
                        *ngIf="
                          product.dayId &&
                          (!isExpanded || col.field === 'artnr')
                        "
                        pTooltip="Kostnaden är kopplad till tidrapporten:<br />{{
                          product.day.date
                        }} {{ product.day.hours }}h av {{
                          product.day.user.firstName
                        }} {{ product.day.user.lastName }}"
                        tooltipPosition="left"
                        [escape]="false"
                        class="pi pi-link"
                      ></i>
                    </span>
                  </ng-template>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      pInputText
                      [(ngModel)]="product[col.field]"
                      (keydown.enter)="updateProduct(product)"
                    />
                  </ng-template>
                </p-cellEditor>
              </span>

              <span
                class="p-text-truncate p-d-block"
                *ngIf="
                  col.field === 'avtalspris' || col.field === 'avtalsprisCost'
                "
              >
                <p-cellEditor (change)="updateProduct(product)">
                  <ng-template pTemplate="output">
                    {{ product[col.field] | comma | currency: 'SEK' }}
                  </ng-template>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      pInputText
                      [(ngModel)]="product[col.field]"
                      (keydown.enter)="updateProduct(product)"
                    />
                  </ng-template>
                </p-cellEditor>
              </span>
              <span class="p-text-truncate" *ngIf="col.field === 'antal'">
                <p-cellEditor (change)="updateProduct(product)">
                  <ng-template pTemplate="output">
                    {{ product.antal }} {{ product.enhet }}
                  </ng-template>
                  <ng-template pTemplate="input">
                    <input
                      appDisableArrowNavigation
                      pInputText
                      [(ngModel)]="product[col.field]"
                      (keydown.enter)="updateProduct(product)"
                    />
                  </ng-template>
                </p-cellEditor>
              </span>
            </td>
            <td *ngIf="!col.editable">
              <span
                class="p-text-truncate p-d-block"
                *ngIf="col.field === 'date'"
                [pTooltip]="
                  isExpanded
                    ? null
                    : 'Tillagd av ' +
                      product.user.firstName +
                      ' ' +
                      product.user.lastName +
                      ' ' +
                      product.created
                "
              >
                <span class="p-d-flex p-jc-between p-ai-center">
                  {{ product.date }}
                  <a
                    *ngFor="
                      let sourceDoc of product.supplierInvoice
                        ?.invoiceSourceDocuments_PreInvoiceTypeHyperion.edges
                    "
                    pTooltip="Visa relaterat dokument"
                    (click)="openPdfFromUrl(sourceDoc.node.url)"
                  >
                    <i class="pi pi-inbox"></i>
                  </a>
                </span>
                <span
                  class="p-mt-1 p-text-truncate p-d-block"
                  style="font-style: italic; line-height: 1.2rem"
                >
                  {{ product.user.firstName }}
                  {{ product.user.lastName }}<br />
                  {{ product.created }}
                </span>
              </span>
              <span
                class="p-text-truncate p-d-block"
                *ngIf="col.field === 'totalSum'"
              >
                {{ product[col.field] | currency: 'SEK' }}
              </span>
              <span
                class="p-text-truncate p-d-block"
                *ngIf="col.field === 'totalSalesSum'"
              >
                {{ product[col.field] | currency: 'SEK' }}
              </span>
              <span *ngIf="col.field === 'invoiceId'">
                <a
                  *ngIf="product.invoiceId"
                  [routerLink]="'/invoice/' + product.invoiceId"
                  pTooltip="Visa relaterad faktura"
                >
                  <i
                    class="pi pi-check"
                    style="color: var(--btn-success-color)"
                  ></i>
                </a>
              </span>
              <ng-container *ngIf="col.field === 'actionMenu'">
                <p-menu
                  appendTo="body"
                  #menu
                  [popup]="true"
                  [model]="productMenus[product.id]"
                ></p-menu>
                <button
                  pButton
                  class="p-button-text legacy-rounded-button"
                  icon="pi pi-ellipsis-v"
                  (click)="
                    selectedEditProjectProductId = product.id;
                    menu.toggle($event)
                  "
                ></button>
              </ng-container>
            </td>
          </ng-container>
        </tr>
      </ng-template>
      <ng-template pTemplate="footer">
        <tr>
          <td [colSpan]="isExpanded ? 6 : 3"></td>
          <td
            [colSpan]="isExpanded ? 0 : 3"
            [style.color]="'var(--primary-color-600)'"
          >
            <span
              *ngIf="mode === modeEnum.COSTS || isExpanded"
              class="p-text-bold"
            >
              {{ (tableData | async).totalCost | currency: 'SEK' }}</span
            >
            <span
              *ngIf="mode === modeEnum.INCOME && !isExpanded"
              class="p-text-bold"
            >
              {{ (tableData | async).totalSalesPrice | currency: 'SEK' }}</span
            >
          </td>
          <td *ngIf="isExpanded" [style.color]="'var(--primary-color-600)'">
            <span class="p-text-bold">
              {{ (tableData | async).totalSalesPrice | currency: 'SEK' }}</span
            >
          </td>
          <td
            *ngIf="isExpanded"
            colspan="2"
            [style.color]="'var(--primary-color-600)'"
          ></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
  <div class="p-col-12 p-d-flex p-jc-end p-ai-center">
    <div
      class="cost-calendar p-d-flex p-flex-wrap p-ai-center p-mr-5"
      *ngIf="isLarge"
    >
      <span class="p-mr-2">Visar kostnader från och med </span>
      <span style="text-wrap: nowrap; flex: 0 1 auto">
        <p-calendar
          [(ngModel)]="displayCostsFromDate"
          dateFormat="dd/mm/yy"
          (onSelect)="updateDisplayedCosts()"
          [showWeek]="true"
        >
          <ng-template pTemplate="footer">
            <p-button
              label="Visa alla"
              styleClass="p-button-text"
              (onClick)="showAllCosts()"
            ></p-button>
          </ng-template>
        </p-calendar>
        <i
          class="pi pi-info-circle p-ml-2"
          style="color: var(--primary-color-400)"
          pTooltip="Eftersom projektet har {{
            totalCostEntries
          }} kostnadsposter kan det vara långsamt att ladda alla på samma gång. Därför kan ni välja från och med vilket datum som kostnaderna ska visas. <br><br>Detta har ingen effekt på projektets totala kostnader, utan enbart vad som visas i denna sektionen."
          tooltipPosition="bottom"
          [escape]="false"
        ></i>
      </span>
    </div>
    <button
      pButton
      class="p-mr-2"
      [label]="'Visa ' + (mode === modeEnum.COSTS ? 'intäkter' : 'kostnader')"
      (click)="changeMode()"
      *ngIf="!isExpanded"
    ></button>
    <button
      pButton
      class="p-button-primary-hyperion"
      label="Lägg till kostnader"
      icon="pi pi-plus"
      (click)="showProductFormSidebar()"
    ></button>
  </div>
</div>

<p-sidebar
  showCloseIcon="false"
  [(visible)]="isProductFormSidebarVisible"
  (onHide)="onHideProductFormSidebar()"
  position="right"
  [style]="{ width: '45rem' }"
>
  <h1 *ngIf="!selectedEditProjectProductId">Lägg till kostnader</h1>
  <h1 *ngIf="selectedEditProjectProductId">Redigera kostnad</h1>
  <app-project-product-form
    [projectId]="projectId"
    [projectProductId]="+selectedEditProjectProductId"
    (projectProductUpdatedEvent)="onProjectProductUpdatedEvent($event)"
    [isExtra]="isExtra"
  ></app-project-product-form>
</p-sidebar>
