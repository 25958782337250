import * as Types from '../../../../generated/types';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DaysListWithDeletedQueryVariables = Types.Exact<{
  projectIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
  userIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
  fromDate?: Types.InputMaybe<Types.Scalars['String']>;
  toDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DaysListWithDeletedQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', daysAllForDeleted?: { __typename?: 'DayConnection', edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, costTypeId?: number | null, date?: string | null, doneWork?: string | null, extra?: number | null, hours?: number | null, hoursToInvoice?: number | null, invoiceId?: number | null, mile?: number | null, mileToInvoice?: number | null, payslipId?: number | null, privMile?: number | null, timeHour?: number | null, isStartstop?: number | null, overtimeHardCoded?: string | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, projectId?: number | null, attendanceTypeId?: number | null, newCostTypeId?: number | null, isLockedForEdit?: boolean | null, parentId?: string | null, todoId?: number | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', companyCostTypeId?: number | null } | null, attendanceType?: { __typename?: 'DayAttendanceType', id: string, code?: string | null, name?: string | null } | null, stats?: { __typename?: 'DayStats', childCount?: number | null, productCount?: number | null } | null, project?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null, status?: number | null, todos?: { __typename?: 'TodoConnection', edges?: Array<{ __typename?: 'TodoEdge', node?: { __typename?: 'Todo', id: string, description?: string | null } | null } | null> | null } | null } | null } | null } | null> | null } | null } | null };

export type DaysListQueryVariables = Types.Exact<{
  projectIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
  userIds?: Types.InputMaybe<Array<Types.InputMaybe<Types.Scalars['Int']>> | Types.InputMaybe<Types.Scalars['Int']>>;
  fromDate?: Types.InputMaybe<Types.Scalars['String']>;
  toDate?: Types.InputMaybe<Types.Scalars['String']>;
}>;


export type DaysListQuery = { __typename?: 'HyperionSchemaQuery', company?: { __typename?: 'Company', daysAll?: { __typename?: 'DayConnection', edges?: Array<{ __typename?: 'DayEdge', node?: { __typename?: 'Day', id: string, costTypeId?: number | null, date?: string | null, doneWork?: string | null, extra?: number | null, hours?: number | null, hoursToInvoice?: number | null, invoiceId?: number | null, mile?: number | null, mileToInvoice?: number | null, payslipId?: number | null, privMile?: number | null, timeHour?: number | null, isStartstop?: number | null, overtimeHardCoded?: string | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, projectId?: number | null, attendanceTypeId?: number | null, newCostTypeId?: number | null, isLockedForEdit?: boolean | null, parentId?: string | null, todoId?: number | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', companyCostTypeId?: number | null } | null, attendanceType?: { __typename?: 'DayAttendanceType', id: string, code?: string | null, name?: string | null } | null, stats?: { __typename?: 'DayStats', childCount?: number | null, productCount?: number | null } | null, project?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null, status?: number | null, todos?: { __typename?: 'TodoConnection', edges?: Array<{ __typename?: 'TodoEdge', node?: { __typename?: 'Todo', id: string, description?: string | null } | null } | null> | null } | null } | null } | null } | null> | null } | null } | null };

export type DayForListFragment = { __typename?: 'Day', id: string, costTypeId?: number | null, date?: string | null, doneWork?: string | null, extra?: number | null, hours?: number | null, hoursToInvoice?: number | null, invoiceId?: number | null, mile?: number | null, mileToInvoice?: number | null, payslipId?: number | null, privMile?: number | null, timeHour?: number | null, isStartstop?: number | null, overtimeHardCoded?: string | null, subsistenceDay?: number | null, subsistenceHalfDay?: number | null, subsistenceNight?: number | null, startTime?: string | null, stopTime?: string | null, userId?: number | null, projectId?: number | null, attendanceTypeId?: number | null, newCostTypeId?: number | null, isLockedForEdit?: boolean | null, parentId?: string | null, todoId?: number | null, user?: { __typename?: 'User', firstName?: string | null, lastName?: string | null } | null, costTypeHyperion?: { __typename?: 'ProjectUserCostTypeExtended', companyCostTypeId?: number | null } | null, attendanceType?: { __typename?: 'DayAttendanceType', id: string, code?: string | null, name?: string | null } | null, stats?: { __typename?: 'DayStats', childCount?: number | null, productCount?: number | null } | null, project?: { __typename?: 'Project', id: string, trueId?: string | null, mark?: string | null, status?: number | null, todos?: { __typename?: 'TodoConnection', edges?: Array<{ __typename?: 'TodoEdge', node?: { __typename?: 'Todo', id: string, description?: string | null } | null } | null> | null } | null } | null };

export const DayForListFragmentDoc = gql`
    fragment DayForList on Day {
  id
  costTypeId
  date
  doneWork
  extra
  hours
  hoursToInvoice
  invoiceId
  mile
  mileToInvoice
  payslipId
  privMile
  timeHour
  isStartstop
  overtimeHardCoded
  subsistenceDay
  subsistenceHalfDay
  subsistenceNight
  startTime
  stopTime
  userId
  user {
    firstName
    lastName
  }
  projectId
  costTypeId
  costTypeHyperion {
    companyCostTypeId
  }
  attendanceTypeId
  attendanceType {
    id
    code
    name
  }
  newCostTypeId
  isLockedForEdit
  parentId
  stats {
    childCount
    productCount
  }
  project {
    id
    trueId
    mark
    status
    todos {
      edges {
        node {
          id
          description
        }
      }
    }
  }
  todoId
}
    `;
export const DaysListWithDeletedDocument = gql`
    query daysListWithDeleted($projectIds: [Int], $userIds: [Int], $fromDate: String, $toDate: String) {
  company {
    daysAllForDeleted(
      projectIds: $projectIds
      userId: $userIds
      timeHour: 1
      fromDate: $fromDate
      toDate: $toDate
    ) {
      edges {
        node {
          ...DayForList
        }
      }
    }
  }
}
    ${DayForListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DaysListWithDeletedGQL extends Apollo.Query<DaysListWithDeletedQuery, DaysListWithDeletedQueryVariables> {
    document = DaysListWithDeletedDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const DaysListDocument = gql`
    query daysList($projectIds: [Int], $userIds: [Int], $fromDate: String, $toDate: String) {
  company {
    daysAll(
      projectIds: $projectIds
      userId: $userIds
      timeHour: 1
      fromDate: $fromDate
      toDate: $toDate
    ) {
      edges {
        node {
          ...DayForList
        }
      }
    }
  }
}
    ${DayForListFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class DaysListGQL extends Apollo.Query<DaysListQuery, DaysListQueryVariables> {
    document = DaysListDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }