import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  Location,
  LocationStrategy,
  PathLocationStrategy,
} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import * as moment from 'moment';

import { GlobalService } from 'app/shared/global/index';
import { HelperService } from 'app/shared/helpers/index';
import { IntroJSService } from 'app/shared/intro-js/index';
import { ProjectCreateComponent } from 'app/shared/dialogs/project/create/projectCreate.component';
import { MailDialogService } from 'app/shared/dialogs/mail/mail-dialog.service';
import { ChosenSolutionService } from 'app/shared/company/index';
import { IntroCompanyFormComponent } from '../intro-screens/index';
import { CompanyNoticeBoardService } from 'app/shared/company/index';
import {
  ApolloMutationService,
  ApolloQueryService,
} from 'app/shared/apollo/index';
import { AppDialogService } from 'app/shared/dialogs/dynamic-dialog.service';
import { LogsComponent } from 'app/company/logs/logs.component';
import { CompanyNotice } from './company-notice-board/company-notice';
import { CompanyFunctionsService } from 'app/shared/company/index';
import { UserLocalStorageService } from 'app/shared/user';
import { MeUserWithCompany } from 'app/shared/user/me-user';
import { MenuItem } from 'primeng/api';
import { first } from 'rxjs/operators';
import { CompanyAppParamsService } from '../../shared/company/index';

const HOME_RECOMMEND_WIDGET_COOKIE_NAME = 'home-recommend-widget-status';

@Component({
  selector: 'home-start',
  providers: [
    AppDialogService,
    Location,
    { provide: LocationStrategy, useClass: PathLocationStrategy },
  ],
  templateUrl: 'home-start.component.html',
  styleUrls: ['./home-start.component.scss'],
})
export class HomeStartComponent implements OnInit, OnDestroy {
  hideStartDivShowWelcomeDiv = false;
  panelOpenState = false;
  showPicturesBool = false;
  location: Location;
  urlPrefix: string;
  id: number;
  showImage = true;
  display = false;
  showPictureFeedForUser = true;
  showPicturesLoading = true;
  showPicturesWaiting = false;
  disableRssNews = false;
  public isDerome = false;
  public isStruqtur = false;
  public menuItems: MenuItem[];
  public activeTabIndex: string;
  public shouldShowRecommendWidget = true;

  meUser: MeUserWithCompany;
  public appParams = {
    supportMail: null,
    supportPhone: null,
    name: null,
    videoFastPresentation: null,
  };

  public dataForChildren: {
    companyNoticeBoard: CompanyNotice[];
    rssNewsFeed: any[];
    upcommingActivities: any[];
  } = {
    companyNoticeBoard: [],
    rssNewsFeed: [],
    upcommingActivities: [],
  };

  companyName: string;
  companyLogo: string;
  firstSectionSub: Subscription;
  loading = {
    firstSection: new BehaviorSubject<boolean>(true),
    secondSection: new BehaviorSubject<boolean>(true),
  };
  dataSet = {
    company: {
      companyNotices_PreCompanyTypeHyperion: {},
      upcommingActivities_PreCompanyTypeHyperion: {},
      rssNewsFeed_PreCompanyTypeHyperion: {},
      projectsCommets: {},
      days: {},
      pictures: {},
    },
  };

  constructor(
    location: Location,
    private route: ActivatedRoute,
    private globalService: GlobalService,
    private helperService: HelperService,
    private introJSService: IntroJSService,
    private apolloQueryService: ApolloQueryService,
    private mutationService: ApolloMutationService,
    private dialogService: AppDialogService,
    private mailDialogService: MailDialogService,
    private chosenSolutionService: ChosenSolutionService,
    private companyNoticeBoardService: CompanyNoticeBoardService,
    private companyFunctionsService: CompanyFunctionsService,
    private userLocalStorageService: UserLocalStorageService,
    private companyAppParamsService: CompanyAppParamsService,
    private cookie: CookieService
  ) {
    this.location = location;
    this.urlPrefix = this.globalService.getUrlPrefix();
    this.meUser = this.userLocalStorageService.getMeUserWithCompany();
  }

  ngOnInit() {
    this.setAppParams();
    this.getFirstSectionData();
    this.checkIfStartup();
    this.setMenuItems();

    this.companyAppParamsService.appParams.pipe(first()).subscribe(data => {
      this.isDerome = data.getenvStruqturMode === 'derome';
      this.isStruqtur = data.getenvStruqturMode === 'struqtur';
    });

    const homeRecommendWidgetCookie = this.cookie.get(
      HOME_RECOMMEND_WIDGET_COOKIE_NAME
    );

    if (homeRecommendWidgetCookie === 'closed') {
      this.shouldShowRecommendWidget = false;
    }
  }

  private setMenuItems(): void {
    this.menuItems = [
      {
        tabindex: 'timeReports',
        label: 'Tidrapporter',
        command: this.changeActiveTabIndex,
      },
      {
        tabindex: 'comments',
        label: 'Kommentarer',
        command: this.changeActiveTabIndex,
      },
      {
        tabindex: 'images',
        label: 'Bilder',
        command: this.changeActiveTabIndex,
      },
    ];
    this.activeTabIndex = this.menuItems[0].tabindex;
  }

  private setAppParams() {
    this.appParams['supportMail'] = localStorage.getItem('appsupportMail');
    this.appParams['supportPhone'] = localStorage.getItem('appsupportPhone');
    this.appParams['name'] = localStorage.getItem('appname');
    if (localStorage.getItem('appvideoFastPresentation')) {
      this.appParams['videoFastPresentation'] = localStorage.getItem(
        'appvideoFastPresentation'
      );
    }
  }

  public closeRecommendWidget(): void {
    this.cookie.set(
      HOME_RECOMMEND_WIDGET_COOKIE_NAME,
      'closed',
      moment().add(1, 'w').toDate()
    );

    this.shouldShowRecommendWidget = false;
  }

  imgError(event) {
    event.target.src = 'https://cdn.browshot.com/static/images/not-found.png';
  }

  public changeActiveTabIndex = event => {
    this.activeTabIndex = event.item.tabindex;
  };

  ngOnDestroy() {
    this.firstSectionSub && this.firstSectionSub.unsubscribe();
  }

  checkUserType = () => this.meUser['type'] === '3';

  getFirstSectionData() {
    this.apolloQueryService
      .apolloWatchQueryTwo(
        'companyStartFirstSection',
        null,
        'cache-and-network'
      )
      .subscribe(({ data, sub }) => {
        this.firstSectionSub = sub;
        if (typeof data !== 'undefined') {
          this.id = +data['company']['id'];
          this.firstSectionSortData(data);
          this.loading.firstSection.next(false);
        }
      });

    this.companyFunctionsService
      .hasCompanyFunction('disableRssNews')
      .subscribe(useFunction => (this.disableRssNews = useFunction));
  }

  firstSectionSortData(data) {
    this.dataSet = data;
    this.dataForChildren.companyNoticeBoard =
      this.companyNoticeBoardService.dataFormater(data.company.companyNotices);
    this.dataForChildren.upcommingActivities = this.helperService.cleanFromNode(
      data.company.upcommingActivities_PreCompanyTypeHyperion
    );
    this.dataForChildren.rssNewsFeed = this.helperService.cleanFromNode(
      data.company.rssNewsFeed_PreCompanyTypeHyperion
    );
    this.companyName = data.company['name'];
    this.companyLogo = data.company['logoUrl'];
    this.handleShowPictures(data);
  }

  checkIfStartup() {
    const snapshot = this.route.snapshot;
    const hasStartup = snapshot.queryParamMap.has('startup');

    if (hasStartup) {
      if (JSON.parse(snapshot.queryParamMap.get('startup'))) {
        this.apolloQueryService
          .apolloQuery('companyInfo')
          .subscribe(({ data }) => {
            if (+data.company['startupSettingsAreDone'] === 0) {
              this.hideStartDivShowWelcomeDiv = true;
              setTimeout(() => {
                this.openCompanyFormDialog();
              }, 0);
            } else {
              this.location.replaceState('home');
            }
          });
      }
    }
  }

  handleShowPictures(data) {
    const showPictures = +data['company']['showProjectPicturesForAllUsers'];
    if (showPictures === 1) {
      this.showPicturesBool = true;
    } else if (+this.meUser['type'] === 1) {
      this.showPictureFeedForUser = false;
    }

    this.showPicturesLoading = false;
  }

  handleChange(e) {
    const isChecked = e.checked;
    this.showPicturesWaiting = true;
    const dataToMutation = {};
    if (isChecked) {
      dataToMutation['showProjectPicturesForAllUsers'] = 1;
    } else {
      dataToMutation['showProjectPicturesForAllUsers'] = 0;
    }

    const executeMutationSub = this.mutationService
      .constructQueryAndExecuteMutation(
        'company',
        'update',
        false,
        dataToMutation,
        ['id', 'showProjectPicturesForAllUsers']
      )
      .subscribe(
        executedData => {
          this.mutationService.displayMutationStatus(executedData);

          this.showPicturesWaiting = false;
          executeMutationSub.unsubscribe();
        },
        err => {
          console.warn(err);
        }
      );
  }

  public openCompanyFormDialog() {
    this.dialogService.layout = 'auto';
    this.dialogService.showHeader = false;
    this.dialogService
      .openComponent(IntroCompanyFormComponent)
      .onClose.subscribe(res => {
        if (typeof res !== 'undefined') {
          this.startIntroRequest(res);
          this.location.replaceState('home');
          this.chosenSolutionService.setChosenSolution(res);
        }
      });
  }

  startIntroRequest(chosenSolution) {
    const introArray = [chosenSolution];
    this.introJSService.pushIntroToSubject(introArray);
  }

  openCreateProjectDialog() {
    this.dialogService.openComponent(ProjectCreateComponent);
  }

  openMailDialog() {
    this.mailDialogService.openMailDialog({ invoiceId: null });
  }

  public openHelp(): void {
    const widget = window.HubSpotConversations.widget;
    if (widget.status().loaded) {
      window.HubSpotConversations.widget.open();
    }
  }

  public openAppLogsDialog() {
    this.dialogService.layout = 'wide';
    this.dialogService.openComponent(LogsComponent);
  }
}
